import { Margin, usePDF } from 'react-to-pdf'
import { Button } from './components/Button'
import { Card } from './components/count-card/CountCard'
import Table from './components/table/Table'
import TiktokCard from './components/tiktokCard/TiktokCard'
import TwitterCard from './components/twitterCard/TwitterCard'
import AdminContentStats from './components/adminConcentStats/AdminContentStats'
import Layout from './components/layout/Layout'

const SocialMediaAccountReport = () => {
  const { toPDF, targetRef } = usePDF({
    filename: 'page.pdf',
    page: {
      orientation: 'l',
      format: 'A4',
      margin: Margin.NONE,
    },
    method: 'save',
    // resolution: Resolution.EXTREME,
    overrides: {
      pdf: {
        compress: true,
      },
      canvas: {
        useCORS: true,
      },
    },
  })

  const reports = [
    {
      title: 'Instagram',
      data: [
        {
          title: 'İçerik Sayısı',
          value: '141',
          details: '50 reels, 10 post, 81 story',
        },
        { title: 'Takipçi Sayısı', value: '3.660.550' },
        { title: 'Takipçi Değişimi', value: '+34.829' },
        {
          title: 'Etkileşim',
          value: '17.738.675',
          details: 'Paid: 17.382.913',
        },
        { title: 'Erişim', value: '56.500.689', details: 'Paid: 50.455.697' },
        { title: 'Gösterim', value: '85.846.895', details: 'Paid: 79.471.337' },
      ],
    },
    {
      title: 'Instagram 2',
      data: [
        {
          title: 'İçerik Sayısı',
          value: '141',
          details: '50 reels, 10 post, 81 story',
        },
        { title: 'Takipçi Sayısı', value: '3.660.550' },
        { title: 'Takipçi Değişimi', value: '+34.829' },
        {
          title: 'Etkileşim',
          value: '17.738.675',
          details: 'Paid: 17.382.913',
        },
        { title: 'Erişim', value: '56.500.689', details: 'Paid: 50.455.697' },
        { title: 'Gösterim', value: '85.846.895', details: 'Paid: 79.471.337' },
      ],
    },
    {
      title: 'Instagram 3',
      data: [
        {
          title: 'İçerik Sayısı',
          value: '141',
          details: '50 reels, 10 post, 81 story',
        },
        { title: 'Takipçi Sayısı', value: '3.660.550' },
        { title: 'Takipçi Değişimi', value: '+34.829' },
        {
          title: 'Etkileşim',
          value: '17.738.675',
          details: 'Paid: 17.382.913',
        },
        { title: 'Erişim', value: '56.500.689', details: 'Paid: 50.455.697' },
        { title: 'Gösterim', value: '85.846.895', details: 'Paid: 79.471.337' },
      ],
    },
  ]
  const columns = [
    'Profil',
    'Takipçi Sayısı',
    'Aylık Takipçi Değişimi',
    'Post Sayısı',
    'Post Başına Etkileşim Ortalaması',
    '1000 Takipçiye Düşen Etkileşim Ortalaması',
  ]
  const data = [
    {
      Profil: 'Avustralya',
      'Takipçi Sayısı': 5862610,
      'Aylık Takipçi Değişimi': -7490,
      'Post Sayısı': 40,
      'Post Başına Etkileşim Ortalaması': 22834,
      '1000 Takipçiye Düşen Etkileşim Ortalaması': 156,
    },
    {
      Profil: 'Go Türkiye',
      'Takipçi Sayısı': 3660550,
      'Aylık Takipçi Değişimi': 34829,
      'Post Sayısı': 60,
      'Post Başına Etkileşim Ortalaması': 47161,
      '1000 Takipçiye Düşen Etkileşim Ortalaması': 773,
    },
    {
      Profil: 'Dubai',
      'Takipçi Sayısı': 3137685,
      'Aylık Takipçi Değişimi': 21145,
      'Post Sayısı': 42,
      'Post Başına Etkileşim Ortalaması': 3874,
      '1000 Takipçiye Düşen Etkileşim Ortalaması': 52,
    },
    {
      Profil: 'İzlanda',
      'Takipçi Sayısı': 1833575,
      'Aylık Takipçi Değişimi': -6625,
      'Post Sayısı': 21,
      'Post Başına Etkileşim Ortalaması': 11524,
      '1000 Takipçiye Düşen Etkileşim Ortalaması': 132,
    },
    {
      Profil: 'İspanya',
      'Takipçi Sayısı': 975559,
      'Aylık Takipçi Değişimi': -659,
      'Post Sayısı': 29,
      'Post Başına Etkileşim Ortalaması': 2978,
      '1000 Takipçiye Düşen Etkileşim Ortalaması': 89,
    },
    {
      Profil: 'İtalya',
      'Takipçi Sayısı': 894684,
      'Aylık Takipçi Değişimi': -579,
      'Post Sayısı': 17,
      'Post Başına Etkileşim Ortalaması': 4068,
      '1000 Takipçiye Düşen Etkileşim Ortalaması': 77,
    },
    {
      Profil: 'İngiltere',
      'Takipçi Sayısı': 797814,
      'Aylık Takipçi Değişimi': 4771,
      'Post Sayısı': 32,
      'Post Başına Etkileşim Ortalaması': 1504,
      '1000 Takipçiye Düşen Etkileşim Ortalaması': 60,
    },
    {
      Profil: 'Yunanistan',
      'Takipçi Sayısı': 685819,
      'Aylık Takipçi Değişimi': -128,
      'Post Sayısı': 28,
      'Post Başına Etkileşim Ortalaması': 2011,
      '1000 Takipçiye Düşen Etkileşim Ortalaması': 82,
    },
    {
      Profil: 'Hong Kong',
      'Takipçi Sayısı': 667482,
      'Aylık Takipçi Değişimi': 2681,
      'Post Sayısı': 20,
      'Post Başına Etkileşim Ortalaması': 1725,
      '1000 Takipçiye Düşen Etkileşim Ortalaması': 52,
    },
    {
      Profil: 'Almanya',
      'Takipçi Sayısı': 620648,
      'Aylık Takipçi Değişimi': 1886,
      'Post Sayısı': 21,
      'Post Başına Etkileşim Ortalaması': 3151,
      '1000 Takipçiye Düşen Etkileşim Ortalaması': 107,
    },
    {
      Profil: 'ABD',
      'Takipçi Sayısı': 425480,
      'Aylık Takipçi Değişimi': 436,
      'Post Sayısı': 20,
      'Post Başına Etkileşim Ortalaması': 30,
      '1000 Takipçiye Düşen Etkileşim Ortalaması': 1,
    },
    {
      Profil: 'Mısır',
      'Takipçi Sayısı': 408271,
      'Aylık Takipçi Değişimi': 158,
      'Post Sayısı': 44,
      'Post Başına Etkileşim Ortalaması': 635,
      '1000 Takipçiye Düşen Etkileşim Ortalaması': 68,
    },
    {
      Profil: 'Fransa',
      'Takipçi Sayısı': 288977,
      'Aylık Takipçi Değişimi': 1781,
      'Post Sayısı': 17,
      'Post Başına Etkileşim Ortalaması': 3057,
      '1000 Takipçiye Düşen Etkileşim Ortalaması': 180,
    },
  ]
  return (
    <>
      <Button onClick={() => toPDF()}>Download PDF</Button>
      <div
        style={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          alignSelf: 'center',
          flexDirection: 'column',
        }}
      >
        <div ref={targetRef}>
          {reports.map((report, index) => (
            <Layout title={report.title} key={index}>
              <Card data={report.data} />
            </Layout>
          ))}
          <Layout title={'Tablo 1'}>
            <Table columns={columns} data={data} />
          </Layout>
          <Layout title={'Tiktok 1'}>
            <div style={{ display: 'flex' }}>
              <TiktokCard
                title="Go Turkiye"
                description="Istanbul is a city that will capture your mood with its unique atmosphere. sdaasdasdadsdsdad
          asdasas
          adda
          sasdasdasda"
                videoUrl="https://example.com/video.mp4"
                likes={12345}
                img="https://example.com/image.jpg"
                comments={678}
                shares={90}
              />
              <TiktokCard
                title="Go Turkiye"
                description="Istanbul is a city that will capture your mood with its unique atmosphere. sdaasdasdadsdsdad
          asdasas
          adda
          sasdasdasda"
                videoUrl="https://example.com/video.mp4"
                likes={12345}
                img="https://example.com/image.jpg"
                comments={678}
                shares={90}
              />
              <TiktokCard
                title="Go Turkiye"
                description="Istanbul is a city that will capture your mood with its unique atmosphere. sdaasdasdadsdsdad
          asdasas
          adda
          sasdasdasda"
                videoUrl="https://example.com/video.mp4"
                likes={12345}
                img="https://example.com/image.jpg"
                comments={678}
                shares={90}
              />
              <TiktokCard
                title="Go Turkiye"
                description="Istanbul is a city that will capture your mood with its unique atmosphere. sdaasdasdadsdsdad
          asdasas
          adda
          sasdasdasda"
                videoUrl="https://example.com/video.mp4"
                likes={12345}
                img="https://example.com/image.jpg"
                comments={678}
                shares={90}
              />
            </div>
          </Layout>
          <Layout title={'Twitter 1'}>
            <div style={{ display: 'flex' }}>
              <TwitterCard
                title="Go Turkiye"
                description="Istanbul is a city that will capture your mood with its unique atmosphere. #Istanbul #Turkey"
                avatar="/avatar.jpg"
                username="GoTurkiye"
                images={[
                  '/twitterİmage.jpeg',
                  '/twitterİmage.jpeg',
                  '/twitterİmage.jpeg',
                  '/twitterİmage.jpeg',
                ]}
              />
              <TwitterCard
                title="Go Turkiye"
                description="Istanbul is a city that will capture your mood with its unique atmosphere. #Istanbul #Turkey"
                avatar="/avatar.jpg"
                username="GoTurkiye"
                images={['/twitterİmage.jpeg', '/twitterİmage.jpeg']}
              />
              <TwitterCard
                title="Go Turkiye"
                description="Istanbul is a city that will capture your mood with its unique atmosphere. #Istanbul #Turkey"
                avatar="/avatar.jpg"
                username="GoTurkiye"
                images={['/twitterİmage.jpeg', '/twitterİmage.jpeg']}
              />
              <TwitterCard
                title="Go Turkiye"
                description="Istanbul is a city that will capture your mood with its unique atmosphere. #Istanbul #Turkey"
                avatar="/avatar.jpg"
                username="GoTurkiye"
                images={['/twitterİmage.jpeg', '/twitterİmage.jpeg']}
              />
            </div>
          </Layout>
          <Layout title={'Aylık emmi 1'}>
            <AdminContentStats />
          </Layout>
        </div>
      </div>
    </>
  )
}

export default SocialMediaAccountReport
