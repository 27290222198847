import * as styled from './AdminContentStats.styled'
import { BsDot } from 'react-icons/bs'

export type AdminContentStatsProps = {
  stats?: {
    month: string
    data: {
      [key: string]: number
    }
  }[]
}

const AdminContentStats: React.FC<AdminContentStatsProps> = ({ stats }) => {
  const stat = [
    {
      month: 'Ocak',
      data: {
        'İçerik Sayısı': 14,
        Erişim: 1400,
        Gösterim: 14000,
        Etkileşim: 140000,
      },
    },
    {
      month: 'Şubat',
      data: {
        'İçerik Sayısı': 15,
        Erişim: 1500,
        Gösterim: 15000,
        Etkileşim: 150000,
      },
    },
  ]

  return (
    <styled.AdminContentStatsContainer>
      {stat.map((stat, index) => (
        <styled.AdminStatsItem key={index}>
          <h1>{stat.month}</h1>
          <styled.AdminStatsData>
            {Object.entries(stat.data).map(([key, value]) => (
              <div key={key}>
                <BsDot
                  style={{
                    color: '#db0000',
                    fontSize: '50px',
                    verticalAlign: 'middle',
                  }}
                />

                <h1>
                  {key} : <span className="value">{value}</span>
                </h1>
              </div>
            ))}
          </styled.AdminStatsData>
        </styled.AdminStatsItem>
      ))}
    </styled.AdminContentStatsContainer>
  )
}

export default AdminContentStats
