import {
  Definition,
  DefinitionList,
  DefinitionResponse,
  CheckVkResponse,
  CheckTumblr,
  CreateStarngageResponse,
  CreateQuoraResponse,
  CreateTumblrResponse,
  CreateTwitterResponse,
  CreateVkResponse,
  CheckEksiSozluk,
  CreateInstagramResponse,
  CreateRedditResponse,
  CreateYoutubeResponse,
  CreateEksiSozlukResponse,
  CreateTiktokResponse,
  CreateMastodonResponse,
  CheckYoutubeResponse,
  TwitterFetchData,
  CreateNewsResponse,
  CreateLinkedinResponse,
  CreateMemurlarResponse,
  CreateBookingResponse,
  CreateInciSozlukResponse,
  CreateAjansPressResponse,
  CreateAirbnbResponse,
  WebsiteResponseValues,
  UludagSozlukResponseValues,
  TripAdvisorResponseValues,
  CreateGoogleTrendsResponse,
  IDefinitionListRequest,
  CreateTwitterQueryRequest,
} from './types'

export const definitionListFilterRequestParams = (
  params: IDefinitionListRequest
) => {
  const tmpRequstData: any = {
    ...params,
  }

  const requestData: any = {}
  for (const key in tmpRequstData) {
    if (
      tmpRequstData[key] !== null &&
      tmpRequstData[key] !== undefined &&
      tmpRequstData[key] !== '' &&
      tmpRequstData[key] !== 0
    ) {
      requestData[key] = tmpRequstData[key]
    }
  }

  return requestData
}

export const mapDefinitionForRequest = (raw: Definition): unknown => {
  console.log(raw, 'mapDefinitionForRequest')
  return {
    id: raw.id,
    crawlingSource: raw.crawlingSource,
    crawlingType: raw.crawlingType,
    fetchComment: raw.fetchComment,
    definitionType: raw.definitionType,
    definitionValue: raw.definitionValue,
    definitionBlackList: raw.definitionBlackLists,
    historicalFetch: raw.historicalFetch
      ? raw.historicalFetch || false
      : undefined,
    period: raw.period ? raw.period || false : undefined,
    periodicFetch: raw.periodicFetch ? raw.periodicFetch : undefined,
    toDate: raw.toDate,
    allowedDbs: raw.allowedDbs,
    countryPerception: raw.countryPerception,
    allowedLanguages: raw.allowedLanguages,
    alarmStatus: raw.alarmStatus,
    newsSourceList: raw.newSourceList,
    startTime: raw.startTime,
    streamLanguage: raw.streamLanguage,
    endTime: raw.endTime,
    environment: raw.environment,
    instagramAlarmOption: raw.instagramAlarmOption,
    useIncomingDefinition: raw.useIncomingDefinition,
    webSiteAlarmOption: raw.webSiteAlarmOption,
    quoraFetchOperation: raw.quoraFetchOperation,
    streamAllowReplies: raw.streamAllowReplies,
    starnGageFetchOption: raw.starnGageFetchOption,
  }
}

export const mapDefinitionForResponse = (raw: any): Definition => ({
  id: raw.data.id,
  crawlTaskId: raw.data.crawlTaskId,
  description: raw.data.description,
  crawlingEndedAt: raw.data.crawlingEndedAt,
  crawlingStartedAt: raw.data.crawlingStartedAt,
  crawlProperties: raw.data.crawlProperties,
  dataSource: raw.data.dataSource,
  environment: raw.environment,
  eventName: raw.data.eventName,
  groupId: raw.data.groupId,
  streamLanguage: raw.streamLanguage,
  insertedInQueueAt: raw.data.insertedInQueueAt,
  paused: raw.data.paused,
  progressPercentage: raw.data.progressPercentage,
  status: raw.data.status,
  pathId: raw.data.pathId,
  toDate: raw.data.toDate,
  instagramAlarmOption: raw.data.instagramAlarmOption,
  useIncomingDefinition: raw.data.useIncomingDefinition,
  webSiteAlarmOption: raw.data.webSiteAlarmOption,
  quoraFetchOperation: raw.data.quoraFetchOperation,
  starnGageFetchOption: raw.data.starnGageFetchOption,
  twitterStreamOption: raw.data.twitterStreamOption,
  crawlingType: raw.data.crawlingType,
})

export const mapDefinitionListForResponse = (raw: any): DefinitionList => ({
  definitionId: raw.data.definitionId,
  definitionTagName: raw.data.definitionTagName,
  definitionValue: raw.definitionValue,
})

export const mapDefinitionForList = (raw: any): DefinitionResponse => ({
  list: raw.data.map((item: unknown) =>
    mapDefinitionForResponse({ data: item })
  ),
  dataCount: raw.datacount,
  pageCount: raw.pageCount,
  message: raw.message,
  success: raw.success,
})
export const mapDefinitionVkForRequest = (raw: any): CheckVkResponse => ({
  crawlingSource: raw.data.crawlingSource,
  fetchCrawling: raw.data.fetchCrawling,
  completedGroupIdList: raw.data.completedGroupIdList,
  processingGroupIdList: raw.data.processingGroupIdList,
})

export const mapDefinitionTumblrForResponse = (raw: any): CheckTumblr => ({
  crawlingSource: raw.crawlingSource,
  crawlingType: raw.crawlingType,
  definitionType: raw.definitionType,
  definitionValue: raw.definitionValue,
})

export const mapDefinitionEksiSozlukForResponse = (
  raw: any
): CheckEksiSozluk => ({
  crawlingSource: raw.crawlingSource,
  crawlingType: raw.crawlingType,
  definitionType: raw.definitionType,
  definitionValue: raw.definitionValue,
})
export const mapDefinitionYoutubeForResponse = (
  raw: any
): CheckYoutubeResponse => ({
  isUrlExist: raw.data.isUrlExist,
  completedGroupIdList: raw.completedGroupIdList,
  processingGroupIdList: raw.data.processingGroupIdList,
  isProcessing: raw.data.isProcessing,
  videoInfo: raw.data.videoInfo,
})

export const mapDefinitionCreateTwitterForRequest = (
  raw: any
): CreateTwitterResponse => ({
  crawlingType: raw.crawlingType,
  definitionType: raw.definitionType,
  definitionValues: raw.definitionValue,
  countryPerception: raw.countryPerception,
  allowedLanguages: raw.allowedLanguages,
  allowedDbs: raw.allowedDbs,
  definitionBlackLists: raw.definitionBlackLists,
  useIncomingDefinition: raw.useIncomingDefinition,
  streamAllowReplies: raw.streamAllowReplies,
  streamLanguage: raw.streamLanguage,
  environment: raw.environment,
  fetchSince: raw.fetchSince,
  fetchUntil: raw.fetchUntil,
  changeTimeIntervalByRefreshInterval: raw.changeTimeIntervalByRefreshInterval,
  periodicFetch: raw.periodicFetch,
  period: raw.period,
  fetchComment: raw.fetchComment,
  conversationId: raw.conversationId,
  historicalFetch: raw.historicalFetch,
  useGnipForFetchByKeyword: raw.useGnipForFetchByKeyword,
  definitionValue: raw?.definitionValue || '',
})

export const mapDefinitionQueryTwitterForRequest = (
  raw: any
): CreateTwitterQueryRequest => ({
  definitionValue: raw?.definitionValue || '',
  definitionType: raw.definitionType,
})

export const mapDefinitionCreateNewsForRequest = (
  raw: any
): CreateNewsResponse => ({
  crawlingType: raw.crawlingType,
  definitionType: raw.definitionType,
  definitionValue: raw.definitionValue,
  countryPerception: raw.countryPerception,
  allowedLanguages: raw.allowedLanguages,
  allowedDbs: raw.allowedDbs,
  definitionBlackLists: raw.definitionBlackLists,
  useIncomingDefinition: raw.useIncomingDefinition,
  environment: raw.environment,
  periodicFetch: raw.periodicFetch,
  period: raw.period,
  startTime: raw.startTime,
  endTime: raw.endTime,
  newsSourceList: raw.newsSourceList,
  alarmStatus: raw.alarmStatus,
})

export const mapDefinitionFetchTwitterForRequest = (
  raw: any
): TwitterFetchData => ({
  data: {
    totalCount: raw.totalCount,
    resultByDates: raw.resultByDates,
  },
  statusCode: raw.statusCode,
})

export const mapDefinitionCreateRedditForRequest = (
  raw: any
): CreateRedditResponse => ({
  crawlingType: raw.crawlingType,
  definitionType: raw.definitionType,
  definitionValue: raw.definitionValue,
  countryPerception: raw.countryPerception,
  allowedLanguages: raw.allowedLanguages,
  allowedDbs: raw.allowedDbs,
  useIncomingDefinition: raw.useIncomingDefinition,
  environment: raw.environment,
  definitionBlackLists: raw.definitionBlackLists,
  periodicFetch: raw.periodicFetch,
  period: raw.period,
})

export const mapDefinitionCreateYoutubeForRequest = (
  raw: any
): CreateYoutubeResponse => ({
  crawlingType: raw.crawlingType,
  definitionType: raw.definitionType,
  definitionValue: raw.definitionValue,
  countryPerception: raw.countryPerception,
  allowedLanguages: raw.allowedLanguages,
  allowedDbs: raw.allowedDbs,
  useIncomingDefinition: raw.useIncomingDefinition,
  environment: raw.environment,
  definitionBlackLists: raw.definitionBlackLists,
  periodicFetch: raw.periodicFetch,
  period: raw.period,
  endAt: raw.endAt,
  startAt: raw.startAt,
  fetchComments: raw.fetchComments,
  expireDayCountCommentTask: raw.expireDayCountCommentTask,
  pageLimit: raw.pageLimit,
  regionCode: raw.regionCode,
})
export const mapDefinitionCreateEksiSozlukForRequest = (
  raw: any
): CreateEksiSozlukResponse => ({
  crawlingType: raw.crawlingType,
  definitionType: raw.definitionType,
  definitionValue: raw.definitionValue,
  countryPerception: raw.countryPerception,
  allowedLanguages: raw.allowedLanguages,
  allowedDbs: raw.allowedDbs,
  useIncomingDefinition: raw.useIncomingDefinition,
  environment: raw.environment,
  definitionBlackLists: raw.definitionBlackLists,
  periodicFetch: raw.periodicFetch,
  period: raw.period,
})
export const mapDefinitionCreateInciSozlukForRequest = (
  raw: any
): CreateInciSozlukResponse => ({
  crawlingType: raw.crawlingType,
  definitionType: raw.definitionType,
  definitionValue: raw.definitionValue,
  countryPerception: raw.countryPerception,
  allowedLanguages: raw.allowedLanguages,
  allowedDbs: raw.allowedDbs,
  useIncomingDefinition: raw.useIncomingDefinition,
  environment: raw.environment,
  definitionBlackLists: raw.definitionBlackLists,
  periodicFetch: raw.periodicFetch,
  period: raw.period,
})
export const mapDefinitionCreateAjansPressForRequest = (
  raw: any
): CreateAjansPressResponse => ({
  crawlingType: raw.crawlingType,
  definitionType: raw.definitionType,
  definitionValue: raw.definitionValue,
  countryPerception: raw.countryPerception,
  allowedLanguages: raw.allowedLanguages,
  allowedDbs: raw.allowedDbs,
  useIncomingDefinition: raw.useIncomingDefinition,
  environment: raw.environment,
  definitionBlackLists: raw.definitionBlackLists,
  periodicFetch: raw.periodicFetch,
  period: raw.period,
  type: raw.type,
  fetchDate: raw.fetchDate,
})
export const mapDefinitionCreateAirbnbForRequest = (
  raw: any
): CreateAirbnbResponse => ({
  crawlingType: raw.crawlingType,
  definitionType: raw.definitionType,
  definitionValue: raw.definitionValue,
  countryPerception: raw.countryPerception,
  allowedLanguages: raw.allowedLanguages,
  allowedDbs: raw.allowedDbs,
  useIncomingDefinition: raw.useIncomingDefinition,
  environment: raw.environment,
  definitionBlackLists: raw.definitionBlackLists,
  searchPropertyParameters: {
    categoryId: raw.categoryId,
    currencyId: raw.currencyId,
    adultCount: raw.adultCount,
    childrenCount: raw.childrenCount,
    infantCount: raw.infantCount,
    checkIn: raw.checkIn,
    checkOut: raw.checkOut,
    priceMin: raw.priceMin,
    priceMax: raw.priceMax,
    minBedRooms: raw.minBedRooms,
    minBeds: raw.minBeds,
    minBathRooms: raw.minBathRooms,
    propertyTypes: raw.propertyTypes,
    hostLanguages: raw.hostLanguages,
    amenities: raw.amenities,
    typeOfPlaces: raw.typeOfPlaces,
    topTierStays: raw.topTierStays,
    selfCheckIn: raw.selfCheckIn,
    instantBook: raw.instantBook,
    superHost: raw.superHost,
  },
  periodicFetch: raw.periodicFetch,
  period: raw.period,
  limit: raw.limit,
  geo: {
    northEastLat: raw.northEastLat,
    northEastLong: raw.northEastLong,
    southWestLat: raw.southWestLat,
    southWestLong: raw.southWestLong,
  },
})
export const mapDefinitionCreateBookingForRequest = (
  raw: any
): CreateBookingResponse => ({
  crawlingType: raw.crawlingType,
  definitionType: raw.definitionType,
  definitionValue: raw.definitionValue,
  countryPerception: raw.countryPerception,
  allowedLanguages: raw.allowedLanguages,
  allowedDbs: raw.allowedDbs,
  useIncomingDefinition: raw.useIncomingDefinition,
  environment: raw.environment,
  definitionBlackLists: raw.definitionBlackLists,
  checkInDate: raw.checkInDate,
  checkOutDate: raw.checkOutDate,
  roomNumber: raw.roomNumber,
  currencyFilter: raw.currencyFilter,
  orderByFilter: raw.orderByFilter,
  adultsNumber: raw.adultsNumber,
  locale: raw.locale,
  locationName: raw.locationName,
  destinationType: raw.destinationType,
  commentsSortType: raw.commentsSortType,
  commentsLanguageFilter: raw.commentsLanguageFilter,
})
export const mapDefinitionCreateGoogleTrendsForRequest = (
  raw: any
): CreateGoogleTrendsResponse => ({
  crawlingType: raw.crawlingType,
  definitionType: raw.definitionType,
  definitionValue: raw.definitionValue,
  countryPerception: raw.countryPerception,
  allowedLanguages: raw.allowedLanguages,
  allowedDbs: raw.allowedDbs,
  useIncomingDefinition: raw.useIncomingDefinition,
  environment: raw.environment,
  definitionBlackLists: raw.definitionBlackLists,
  periodicFetch: raw.periodicFetch,
  period: raw.period,
  category: raw.category,
  date: raw.date,
  geo: raw.geo,
  group: raw.group,
  label: raw.label,
  query: raw.query,
  region: raw.region,
  timeZone: raw.timeZone,
})
export const mapDefinitionCreateVkForRequest = (
  raw: any
): CreateVkResponse => ({
  crawlingType: raw.crawlingType,
  definitionType: raw.definitionType,
  definitionValue: raw.definitionValue,
  countryPerception: raw.countryPerception,
  allowedLanguages: raw.allowedLanguages,
  allowedDbs: raw.allowedDbs,
  useIncomingDefinition: raw.useIncomingDefinition,
  periodicFetch: raw.periodicFetch,
  period: raw.period,
  startTime: raw.startTime,
  endTime: raw.endTime,
})
export const mapDefinitionTumblrForRequest = (
  raw: any
): CreateTumblrResponse => ({
  crawlingType: raw.crawlingType,
  definitionType: raw.definitionType,
  definitionValue: raw.definitionValue,
  countryPerception: raw.countryPerception,
  allowedLanguages: raw.allowedLanguages,
  allowedDbs: raw.allowedDbs,
  useIncomingDefinition: raw.useIncomingDefinition,
  environment: raw.environment,
  definitionBlackLists: raw.definitionBlackLists,
  periodicFetch: raw.periodicFetch,
  period: raw.period,
  toDate: raw.toDate,
})
export const mapDefinitionInstagramForRequest = (
  raw: any
): CreateInstagramResponse => ({
  crawlingType: raw.crawlingType,
  definitionType: raw.definitionType,
  definitionValue: raw.definitionValue,
  countryPerception: raw.countryPerception,
  allowedLanguages: raw.allowedLanguages,
  allowedDbs: raw.allowedDbs,
  useIncomingDefinition: raw.useIncomingDefinition,
  environment: raw.environment,
  definitionBlackLists: raw.definitionBlackLists,
  periodicFetch: raw.periodicFetch,
  period: raw.period,
  instagramFetchKeywordOption: raw.instagramFetchKeywordOption,
  instagramAlarmOption: raw.instagramAlarmOption,
  fetchComments: raw.fetchComments,
})

export const mapDefinitionQuoraForRequest = (
  raw: any
): CreateQuoraResponse => ({
  crawlingType: raw.crawlingType,
  definitionType: raw.definitionType,
  definitionValue: raw.definitionValue,
  countryPerception: raw.countryPerception,
  allowedLanguages: raw.allowedLanguages,
  allowedDbs: raw.allowedDbs,
  useIncomingDefinition: raw.useIncomingDefinition,
  environment: raw.environment,
  definitionBlackLists: raw.definitionBlackLists,
  periodicFetch: raw.periodicFetch,
  period: raw.period,
  fetchLimit: raw.fetchLimit,
  dateType: raw.dateType,
})
export const mapDefinitionTiktokForRequest = (
  raw: any
): CreateTiktokResponse => ({
  crawlingType: raw.crawlingType,
  definitionType: raw.definitionType,
  definitionValue: raw.definitionValue,
  countryPerception: raw.countryPerception,
  allowedLanguages: raw.allowedLanguages,
  allowedDbs: raw.allowedDbs,
  useIncomingDefinition: raw.useIncomingDefinition,
  periodicFetch: raw.periodicFetch,
  period: raw.period,
  environment: raw.environment,
})
export const mapDefinitionStarngageForRequest = (
  raw: any
): CreateStarngageResponse => ({
  crawlingType: raw.crawlingType,
  definitionType: raw.definitionType,
  definitionValue: raw.definitionValue,
  countryPerception: raw.countryPerception,
  allowedLanguages: raw.allowedLanguages,
  allowedDbs: raw.allowedDbs,
  useIncomingDefinition: raw.useIncomingDefinition,
  environment: raw.environment,
  definitionBlackLists: raw.definitionBlackLists,
  periodicFetch: raw.periodicFetch,
  period: raw.period,
  value: raw.value,
  sortBy: raw.sortBy,
  minInfluencerReach: raw.minInfluencerReach,
  maxInfluencerReach: raw.maxInfluencerReach,
  engagementRate: raw.engagementRate,
  influencerCountry: raw.influencerCountry,
  audienceCountry: raw.audienceCountry,
  gender: raw.gender,
  topicsOfInfluence: raw.topicsOfInfluence,
  nameSearch: raw.nameSearch,
})
export const mapDefinitionWebsiteForRequest = (
  raw: any
): WebsiteResponseValues => ({
  crawlingType: raw.crawlingType,
  definitionType: raw.definitionType,
  definitionValue: raw.definitionValue,
  countryPerception: raw.countryPerception,
  allowedLanguages: raw.allowedLanguages,
  allowedDbs: raw.allowedDbs,
  useIncomingDefinition: raw.useIncomingDefinition,
  environment: raw.environment,
  definitionBlackLists: raw.definitionBlackLists,
  periodicFetch: raw.periodicFetch,
  period: raw.period,
  websiteGoogleRecommendationOption: {
    value: raw.value,
  },
})
export const mapDefinitionUludagSozlukForRequest = (
  raw: any
): UludagSozlukResponseValues => ({
  crawlingType: raw.crawlingType,
  definitionType: raw.definitionType,
  definitionValue: raw.definitionValue,
  countryPerception: raw.countryPerception,
  allowedLanguages: raw.allowedLanguages,
  allowedDbs: raw.allowedDbs,
  useIncomingDefinition: raw.useIncomingDefinition,
  environment: raw.environment,
  definitionBlackLists: raw.definitionBlackLists,
  periodicFetch: raw.periodicFetch,
  period: raw.period,
  isDescending: raw.isDescending,
})
export const mapDefinitionTripAdvisorForRequest = (
  raw: any
): TripAdvisorResponseValues => ({
  crawlingType: raw.crawlingType,
  definitionType: raw.definitionType,
  definitionValue: raw.definitionValue,
  countryPerception: raw.countryPerception,
  allowedLanguages: raw.allowedLanguages,
  allowedDbs: raw.allowedDbs,
  useIncomingDefinition: raw.useIncomingDefinition,
  environment: raw.environment,
  definitionBlackLists: raw.definitionBlackLists,
  periodicFetch: raw.periodicFetch,
  period: raw.period,
  maxReviews: raw.maxReviews,
  lastReviewDate: raw.lastReviewDate,
  languages: raw.languages,
})

export const mapDefinitionMastodonForRequest = (
  raw: any
): CreateMastodonResponse => ({
  crawlingType: raw.crawlingType,
  definitionType: raw.definitionType,
  definitionValue: raw.definitionValue,
  countryPerception: raw.countryPerception,
  allowedLanguages: raw.allowedLanguages,
  allowedDbs: raw.allowedDbs,
  useIncomingDefinition: raw.useIncomingDefinition,
  environment: raw.environment,
  definitionBlackLists: raw.definitionBlackList,
  periodicFetch: raw.periodicFetch,
  period: raw.period,
})
export const mapDefinitionLinkedinForRequest = (
  raw: any
): CreateLinkedinResponse => ({
  crawlingType: raw.crawlingType,
  definitionType: raw.definitionType,
  definitionValue: raw.definitionValue,
  countryPerception: raw.countryPerception,
  allowedLanguages: raw.allowedLanguages,
  allowedDbs: raw.allowedDbs,
  useIncomingDefinition: raw.useIncomingDefinition,
  environment: raw.environment,
  definitionBlackLists: raw.definitionBlackLists,
  includeAccounts: raw.includeAccounts,
  location: raw.location,
  size: raw.size,
  industries: raw.industries,
  locations: raw.locations,
})

export const mapDefinitionMemurlarForRequest = (
  raw: any
): CreateMemurlarResponse => ({
  crawlingType: raw.crawlingType,
  definitionType: raw.definitionType,
  definitionValue: raw.definitionValue,
  countryPerception: raw.countryPerception,
  allowedLanguages: raw.allowedLanguages,
  allowedDbs: raw.allowedDbs,
  useIncomingDefinition: raw.useIncomingDefinition,
  environment: raw.environment,
  definitionBlackLists: raw.definitionBlackLists,
  periodicFetch: raw.periodicFetch,
  period: raw.period,
  fetchLimit: raw.fetchLimit,
  topic: raw.topic,
})
