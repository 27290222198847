import React, { useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Column } from '@zera-admin/page'
import Form, { FormSection } from '@zera-admin/form'

import SelectIcon from 'components/select-icon/SelectIcon'
import EksiSozlukLogo from '../../assets/images/eksi.png'
import NewsLogo from '../../assets/images/newspaper.png'
import X from '../../assets/images/xwhite.png'
import inci from '../../assets/images/inci.png'
import InstagramLogo from '../../assets/images/instagram-logo.svg'
import LinkedinLogo from '../../assets/images/linkedin-logo.svg'
import QuoraLogo from '../../assets/images/quora-logo.svg'
import MemurlarLogo from '../../assets/images/memurlar-logo.png'
import MastodonLogo from '../../assets/images/mastodon-logo.svg'
import booking from '../../assets/images/booking.svg'
import ajansPress from '../../assets/images/ajansPress.png'
import airbnb from '../../assets/images/airbnb.png'
import WebsiteLogo from '../../assets/images/websitelogo.png'
import Uludag from '../../assets/images/uludag.jpg'
import TripAdvisor from '../../assets/images/tripadvisor.png'
import googleTrends from '../../assets/images/googleTrends.png'

import { DefinitionFormProps, SocialMediaEnum } from './types'

const DefinitionForm: React.FunctionComponent<DefinitionFormProps> = ({
  actions = [],
  onError,
  onSubmit,
  values,
}) => {
  const ref = useRef()

  const [tab, setTab] = useState<SocialMediaEnum>()
  const [socialList, setSocialList] = useState<any>([
    {
      label: 'Ekşi Sozluk',
      value: 'eksi-sozluk',
      img: EksiSozlukLogo,
    },
    {
      label: 'İnci Sözluk',
      value: 'inci-sozluk',
      img: inci,
    },
    {
      label: 'Google Trends',
      value: 'google-trends',
      img: googleTrends,
    },
    {
      label: 'Booking',
      value: 'booking',
      img: booking,
    },
    {
      label: 'Airbnb',
      value: 'airbnb',
      img: airbnb,
    },
    {
      label: 'Reddit',
      value: 'reddit',
      icon: 'fa-brands fa-reddit',
    },
    {
      label: 'Tumblr',
      value: 'tumblr',
      icon: 'fa-brands fa-tumblr',
    },
    {
      label: 'X',
      value: 'x',
      img: X,
    },
    {
      label: 'VK',
      value: 'vk',
      icon: 'fa-brands fa-vk',
    },
    {
      label: 'Starngage',
      value: 'starngage',
      icon: 'fa-brands fa-vk',
    },
    {
      label: 'Tiktok',
      value: 'tiktok',
      icon: 'fa-brands fa-tiktok',
    },
    {
      label: 'TripAdvisor',
      value: 'tripadvisor',
      img: TripAdvisor,
    },
    {
      label: 'Uludağ Sözlük',
      value: 'uludag-sozluk',
      img: Uludag,
    },
    {
      label: 'Website',
      value: 'website',
      img: WebsiteLogo,
    },
    {
      label: 'YouTube',
      value: 'youtube',
      icon: 'fa-brands fa-youtube',
      color: 'red',
    },
    {
      label: 'Haberler',
      value: 'news',
      img: NewsLogo,
    },
    {
      label: 'AjansPress',
      value: 'ajans-press',
      img: ajansPress,
    },
    {
      label: 'Instagram',
      value: 'instagram',
      img: InstagramLogo,
    },
    {
      label: 'Linkedin',
      value: 'linkedin',
      img: LinkedinLogo,
    },
    {
      label: 'Quora',
      value: 'quora',
      img: QuoraLogo,
    },
    {
      label: 'Memurlar',
      value: 'memurlar',
      img: MemurlarLogo,
    },
    {
      label: 'Mastodon',
      value: 'mastodon',
      img: MastodonLogo,
    },
  ])

  const history = useHistory()

  return (
    <Form>
      <FormSection
        title="Veri toplama kaynağı"
        description="Veri Toplama Kaynağı oluşturmak için bir sosyal medya kaynağı seçmeniz gerekmektedir."
      >
        <Column xs={{ size: 8 }}>
          <SelectIcon
            list={socialList}
            title="Oluşturmak için kaynak seçiniz"
            placeholder="Seçim yapınız"
            onChange={(props: any) => history.push(`create/${props}`)}
          />
        </Column>
      </FormSection>
      {/* <FormFooter>{actions}</FormFooter> */}
    </Form>
  )
}

export default DefinitionForm
