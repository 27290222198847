import React from "react";
import * as Styled from "./Table.styled";

export type TableProps = {
  columns: string[]; // Tablo başlıkları
  data: { [key: string]: string | number }[]; // Satırlar (dizilerdeki objeler)
};

const Table: React.FC<TableProps> = ({ columns, data }) => {
  return (
    <Styled.TableContainer>
      <Styled.StyledTable>
        <thead>
          <Styled.Header>
            {columns.map((column, index) => (
              <th key={index}>{column}</th>
            ))}
          </Styled.Header>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <Styled.Row key={rowIndex} isBold={row["Profil"] === "Go Türkiye"}>
              {columns.map((column, colIndex) => (
                <td key={colIndex}>{row[column]}</td>
              ))}
            </Styled.Row>
          ))}
        </tbody>
      </Styled.StyledTable>
    </Styled.TableContainer>
  );
};

export default Table;
