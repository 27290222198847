import styled from 'styled-components'

export const TiktokCardContainer = styled.div`
  position: relative;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  height: 533px;
  margin: 1rem;
  overflow: hidden;
  .img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
  .stats-column {
    position: absolute;
    bottom: 0;
    right: 0;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    .stat-icon {
      color: white;
      font-size: 2rem;
      margin: 0.3rem;
      width: 2.3rem;
    }
    span {
      font-size: 12px;
    }
    .avatar-icon {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      overflow: hidden;
      margin: 0.5rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .follow-button {
        position: absolute;
        top: 38px;
        right: 19px;
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 50%;
        background-color: #ff2851;
        display: flex;
        align-items: center;
        justify-content: center;
        .plus {
          color: white;
          font-size: 1rem;
        }
      }
    }
  }
  .bottom {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 0.5rem;
    width: 300px;
    .username {
      display: flex;
      align-items: center;
      margin-bottom: 0.1rem;
      gap: 0.3px;
      .verified {
        color: #00b3e6;
        font-size: 1.2rem;
        background-color: white;
        border-radius: 50%;
        width: 0.9rem;
        height: 0.9rem;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .title {
        font-weight: 600;
        color: white;
        font-size: 0.8rem;
      }
    }
    .description {
      text-overflow: ellipsis;
      font-weight: 500;
      max-width: 250px;
      color: white;
      font-size: 0.7rem;
    }
    .music {
      margin-top: 0.5rem;
      opacity: 0.8;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: white;
      font-size: 0.6rem;
    }
  }
`
