import './CountCard.css'

interface CardProps {
  data: any
}

export const Card = ({ data }: CardProps) => {
  return (
    <div className="pdf-card-container">
      <div className="grid">
        {data?.map((item: any, index: number) => (
          <div className="box" key={index}>
            <h2>{item.title}</h2>
            <p>
              <strong>{item.value}</strong>
            </p>
            {item.details && <p>{item.details}</p>}
          </div>
        ))}
      </div>

      <div className="footer">
        <p>
          <strong>Notlar:</strong>
        </p>
        <p>
          Ekim ayında sponsorlu içeriklerde <em>Gastro ve Waterfalls</em>{' '}
          kategori içerikleri yüksek etkileşim almıştır.
        </p>
        <p>
          Organik içeriklerde ise <em>Architectural Marvels</em>,{' '}
          <em>Nature & Ecolife</em> ve <em>Özel Gün</em> kategori içerikleri en
          yüksek etkileşimi almıştır.
        </p>
        <p>
          2024 yılı hedefimiz olan 4 milyon takipçi sayısına mevcut performans
          stratejimiz ile ulaşmamız normal şartlarda mümkün gözükmemektedir.
          Hedefimize ulaşabilmemiz için her ay ortalama +125.000 takipçiye
          ihtiyacımız bulunuyor.
        </p>
        <p>
          Reklam hedef kitlemizi daha ucuz olan ülkelere yönlendirmemiz bu
          hedefe ulaşmamız için alternatif bir çözüm olarak tarafınıza
          sunulmaktadır.
        </p>
      </div>
    </div>
  )
}
