import styled from "styled-components";

export const TableContainer = styled.div`
  overflow-x: auto;
  margin: 20px 0;
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
`;

export const Header = styled.tr`
  background-color: #a80707;

  th {
    color: white;
    padding: 10px;
    text-align: center;
    border: 1px solid #000;
  }
`;

interface RowProps {
  isBold?: boolean;
}

export const Row = styled.tr<RowProps>`
  td {
    padding: 5px;
    border: 1px solid #000;
    font-weight: ${(props) => (props.isBold ? "bold" : "normal")};
    text-align: center;

    &:first-child {
      background-color: #bfbdbd;
      width: 200px;
    }
  }

  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;
