import React, { useEffect, useState } from 'react'
import MainLayout from 'layouts/main'
import { useFlagcardContext } from 'app/contexts/flagcard'
import { getCountryPerceptions } from '../../../services/http/backoffice/country-perception/endpoints'
import backofficeDefinitionService from 'services/http/backoffice/definition/'

import { StarngageResponseValues } from './../../../services/http/backoffice/definition/types'

import Page from '@zera-admin/page'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import { Column, Row, PageHeader } from '@zera-admin/page'
import Button from '@zera-admin/button'
import Input from '@zera-admin/input'
import Select, { SelectOptionProps } from '@zera-admin/select'
import Checkbox from '@zera-admin/checkbox'
import Icon from '@zera-admin/icon'
import Form, {
  FormSection,
  useForm,
  FormHookSubmitProps,
  FormFooter,
  FormHeader,
} from '@zera-admin/form'

const StarnGage = () => {
  const [formValues, setFormValues] = useState<{
    countryPerceptions: Array<SelectOptionProps>
  }>({
    countryPerceptions: [],
  })

  const [flagcard] = useFlagcardContext()

  useEffect(() => {
    getCountryPerceptions()
      .then((res) => res.data.data)
      .then((res) =>
        setFormValues((state) => ({
          ...state,
          countryPerceptions: res.map((country: any) => {
            return { label: country?.description, value: country?.key }
          }),
        }))
      )
  }, [])

  const initialValues: StarngageResponseValues = {
    crawlingType: 'fetch',
    definitionType: 'filter',
    countryPerception: 1,
    allowedLanguages: undefined,
    allowedDbs: undefined,
    useIncomingDefinition: undefined,
    environment: 'Alice',
    definitionBlackLists: undefined,
    periodicFetch: undefined,
    period: undefined,
    value: undefined,
    sortBy: undefined,
    minInfluencerReach: undefined,
    maxInfluencerReach: undefined,
    engagementRate: undefined,
    influencerCountry: undefined,
    audienceCountry: undefined,
    gender: undefined,
    topicsOfInfluence: undefined,
    nameSearch: undefined,
  }

  const form = useForm<StarngageResponseValues>({
    initialValues,
    onSubmit: (props) => {
      handlerSubmit(props)
    },
  })

  const handlerSubmit = (
    props: FormHookSubmitProps<StarngageResponseValues>
  ) => {
    console.log(form.values)
    if (!props.errors) {
      if (form.values) {
        backofficeDefinitionService
          .createStarngage(form.values)
          .then(() => {
            flagcard.add({
              appearance: 'success',
              title: 'Kayıt işlemi başarılı',
              children:
                'Girmiş olduğunuz bilgiler ile birlikte veri sisteme başarıyla kayıt edilmiştir. Dilerseniz yeni veri ekleyebilirsiniz.',
            })
            form.handleBatchUpdate(initialValues)
            form.handleReset()
          })
          .catch((error) => {
            console.log(error)

            flagcard.add({
              appearance: 'error',
              title: 'Bir hata oluştu',
              children:
                'Veri eklenirken bir hata oluştu. Lütfen tekrar deneyin. Sorununuz düzelmediyse servis sağlayıcısı ile iletişime geçiniz.',
            })
            form.handleBatchUpdate(initialValues)
            form.handleReset()
          })
      }
    }
  }
  console.log(form.values)

  const renderPageContent = () => {
    return (
      <React.Fragment>
        <Form onSubmit={form.handleSubmit}>
          <FormHeader
            title="Starngage Tanımlama Formu"
            description={
              <div style={{ marginBottom: '1.5rem' }}>
                <p>Form üzerinden aşağıdaki genel bilgileri doldurunuz.</p>
                <p style={{ margin: '0', color: '#A73121' }}>
                  Doldurulması zorunlu alanlar (*) işareti ile belirtilmiştir.
                </p>
              </div>
            }
          />
          <FormSection>
            <Row xs={{ direction: 'column' }}>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Input
                  label="*Definition Value"
                  name="definitionValue"
                  placeholder="Takip edilecek kelime giriniz."
                  isRequired
                  onChange={(props) =>
                    form.handleFieldChange(
                      'definitionValue',
                      props.currentTarget.value
                    )
                  }
                  value={form.values.definitionValue}
                />
              </Column>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Select
                  label="Dil Seçiniz"
                  name="allowedLanguages"
                  description="*İzin verilen diller listesi"
                  placeholder="Seçim yapın"
                  isMultiple
                  options={[
                    { value: 'Turkish', label: 'Turkish' },
                    { value: 'English', label: 'English' },
                  ]}
                  onChange={(props) => {
                    const value = props as Array<SelectOptionProps>
                    form.handleFieldChange(
                      'allowedLanguages',
                      value.length > 0
                        ? value.map((lang) => lang.value)
                        : undefined
                    )
                  }}
                  value={form.values.allowedLanguages?.map((lang) => {
                    return { value: lang, label: lang }
                  })}
                />
              </Column>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Select
                  label="Algı Seçiniz"
                  name="countryPerception"
                  options={formValues.countryPerceptions}
                  placeholder="Seçim yapın"
                  defaultValue={
                    formValues.countryPerceptions.find(
                      (country) => country.label === 'Türkiye'
                    ) || { label: 'Türkiye', value: 1 }
                  }
                  value={formValues.countryPerceptions.find(
                    (country) => country.value === form.values.countryPerception
                  )}
                  onChange={(e) => {
                    form.handleFieldChange(
                      'countryPerception',
                      (e as SelectOptionProps).value
                    )
                  }}
                />
              </Column>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Select
                  label="Database Seçimi"
                  name="allowedDbs"
                  placeholder="Seçim yapın"
                  description="*Çekilen verilerin işlendikten sonra hangi db 'lere kaydedileceğini belirtir."
                  isMultiple
                  options={[
                    { value: 'oracle', label: 'Oracle' },
                    { value: 'druid', label: 'Druid' },
                    { value: 'neo4j', label: 'Neo4j' },
                  ]}
                  onChange={(props) => {
                    const value = props as Array<SelectOptionProps>
                    form.handleFieldChange(
                      'allowedDbs',
                      value.length > 0 ? value.map((db) => db.value) : undefined
                    )
                  }}
                  value={form.values.allowedDbs?.map((db) => {
                    return { value: db.toLowerCase(), label: db }
                  })}
                />
              </Column>
              <Column xs={{ size: 12 }} xl={{ size: 6 }}>
                <Checkbox
                  name="useIncomingDefinition"
                  label="Bu definitiondan gelen postlar direkt olarak bu definition ile
                  işaretlensin mi ?"
                  onChange={(props) => {
                    form.handleFieldChange(
                      'useIncomingDefinition',
                      props.currentTarget.checked
                    )
                  }}
                  defaultChecked={form.values.useIncomingDefinition}
                  isChecked={form.values.useIncomingDefinition}
                />
              </Column>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Select
                  label="Ortam seçiniz"
                  name="environment"
                  options={[
                    { label: 'Alice', value: 'Alice' },
                    { label: 'Thoth', value: 'Thoth' },
                    { label: 'Thoth Local', value: 'ThothLocal' },
                  ]}
                  onChange={(selectedOption) => {
                    const selectedValue = (selectedOption as SelectOptionProps)
                      .value
                    form.handleFieldChange('environment', selectedValue)
                  }}
                  placeholder="Seçim yapın"
                  isMultiple={false}
                />
              </Column>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Select
                  label="Definition Black Lists"
                  name="definitionBlackLists"
                  description="*Bu definition özelinde blacklist."
                  placeholder="Seçim yapın"
                  isMultiple={true}
                  options={[{ label: 'BlackListWord', value: 'BlackListWord' }]}
                  onChange={(props) => {
                    const value = props as Array<SelectOptionProps>
                    form.handleFieldChange(
                      'definitionBlackLists',
                      value.length > 0
                        ? value.map((blackList) => blackList.value)
                        : undefined
                    )
                  }}
                  value={form.values.definitionBlackLists?.map((blackList) => ({
                    label: blackList,
                    value: blackList,
                  }))}
                />
              </Column>
              <Column xs={{ size: 12 }} xl={{ size: 6 }}>
                <Checkbox
                  label="Periyodik olarak çekilsin mi ?"
                  name="periodicFetch"
                  onChange={(props) => {
                    form.handleFieldChange(
                      'periodicFetch',
                      props.currentTarget.checked
                    )
                  }}
                  isChecked={form.values.periodicFetch}
                />
              </Column>
              {form.values.periodicFetch && (
                <Column xs={{ size: 12 }} md={{ size: 6 }} xl={{ size: 4 }}>
                  <Input
                    name="period"
                    placeholder="Periyot giriniz (0 * * * *)"
                    description="Geçerli bir periyot giriniz"
                    onChange={(props) =>
                      form.handleFieldChange(
                        'period',
                        props.currentTarget.value
                      )
                    }
                    value={form.values.period}
                    isRequired={form.values.periodicFetch}
                  />
                </Column>
              )}
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Input
                  label="Value"
                  name="value"
                  placeholder="Bir değer giriniz."
                  onChange={(props) =>
                    form.handleFieldChange('value', props.currentTarget.value)
                  }
                  value={form.values.value}
                />
              </Column>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Input
                  label="Sıralama değeri"
                  name="sortBy"
                  placeholder="Verileri sıralamak için kelime giriniz."
                  onChange={(props) =>
                    form.handleFieldChange('sortBy', props.currentTarget.value)
                  }
                  value={form.values.sortBy}
                />
              </Column>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Input
                  label="minInfluencerReach"
                  name="minInfluencerReach"
                  min={0}
                  onChange={(props) =>
                    form.handleFieldChange(
                      'minInfluencerReach',
                      Number(props.currentTarget.value)
                    )
                  }
                  type="number"
                  value={form.values.minInfluencerReach}
                />
              </Column>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Input
                  label="maxInfluencerReach"
                  name="maxInfluencerReach"
                  min={0}
                  onChange={(props) =>
                    form.handleFieldChange(
                      'maxInfluencerReach',
                      Number(props.currentTarget.value)
                    )
                  }
                  type="number"
                  value={form.values.maxInfluencerReach}
                />
              </Column>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Input
                  label="engagementRate"
                  name="engagementRate"
                  min={0}
                  onChange={(props) =>
                    form.handleFieldChange(
                      'engagementRate',
                      Number(props.currentTarget.value)
                    )
                  }
                  type="number"
                  value={form.values.engagementRate}
                />
              </Column>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Input
                  label="influencerCountry"
                  name="influencerCountry"
                  placeholder="influencerCountry"
                  onChange={(props) =>
                    form.handleFieldChange(
                      'influencerCountry',
                      props.currentTarget.value
                    )
                  }
                  value={form.values.influencerCountry}
                />
              </Column>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Input
                  label="audienceCountry"
                  name="audienceCountry"
                  placeholder="audienceCountry"
                  onChange={(props) =>
                    form.handleFieldChange(
                      'audienceCountry',
                      props.currentTarget.value
                    )
                  }
                  value={form.values.audienceCountry}
                />
              </Column>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Input
                  label="gender"
                  name="gender"
                  placeholder="gender"
                  onChange={(props) =>
                    form.handleFieldChange('gender', props.currentTarget.value)
                  }
                  value={form.values.gender}
                />
              </Column>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Input
                  label="topicsOfInfluence"
                  name="topicsOfInfluence"
                  placeholder="topicsOfInfluence"
                  onChange={(props) =>
                    form.handleFieldChange(
                      'topicsOfInfluence',
                      props.currentTarget.value
                    )
                  }
                  value={form.values.topicsOfInfluence}
                />
              </Column>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Input
                  label="nameSearch"
                  name="nameSearch"
                  placeholder="nameSearch"
                  onChange={(props) =>
                    form.handleFieldChange(
                      'nameSearch',
                      props.currentTarget.value
                    )
                  }
                  value={form.values.nameSearch}
                />
              </Column>
            </Row>
          </FormSection>
          <FormFooter align="start">
            <Button
              type="submit"
              appearance="primary"
              children="Tanımlama Oluştur"
              iconBefore={<Icon name="add-item" />}
            />
          </FormFooter>
        </Form>
      </React.Fragment>
    )
  }

  return (
    <MainLayout>
      <Page>
        <PageHeader
          breadcrumbs={
            <Breadcrumb>
              <BreadcrumbItem text="Anasayfa" href="/" />
              <BreadcrumbItem text="Tanımlama" href="/definition" />
              <BreadcrumbItem
                text="Tanımlama oluştur"
                href="/definition/create"
              />
              <BreadcrumbItem text="Starngage" />
            </Breadcrumb>
          }
        />
        {renderPageContent()}
      </Page>
    </MainLayout>
  )
}

export default StarnGage
