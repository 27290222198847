import React from 'react'
import * as Styled from './Layout.styled'
import bukafa from './assets/bukafa.png'
import ministry from './assets/ministry.png'
import tga from './assets/tga.png'

const Layout = ({
  children,
  title,
}: {
  children: React.ReactNode
  title: string
}) => {
  return (
    <Styled.LayoutWrapper>
      <div className="report-aside"></div>
      <div className="report-content">
        <div className="report-header">
          <div className="report-header-title">
            <h1>{title}</h1>
          </div>
          <div className="report-header-logos">
            <div className="bukafa">
              <img src={bukafa} alt="bukafa" />
            </div>

            <div className="ministry-tga">
              <img src={ministry} alt="ministry" />
              <img src={tga} alt="tga" />
            </div>
          </div>
        </div>

        {children}
      </div>
    </Styled.LayoutWrapper>
  )
}

export default Layout
