import React, { useEffect, useState } from 'react'

import Flagcard from 'components/flagcard'
import NoInternetConnection from 'components/no-internet-connection'
import Popup from 'components/popup'

const App = ({ children }: { children: React.ReactElement }) => {
  const [onLine, setOnLine] = useState(window.navigator.onLine)

  const handleOffline = () => {
    setOnLine(false)
  }

  const handleOnline = () => {
    setOnLine(true)
  }

  useEffect(() => {
    window.addEventListener('offline', handleOffline)
    window.addEventListener('online', handleOnline)

    return function cleanup() {
      window.removeEventListener('offline', handleOffline)
      window.removeEventListener('online', handleOnline)
    }
  }, [])

  if (onLine) {
    return (
      <React.StrictMode>
        <Flagcard />
        <Popup />
        {children}
      </React.StrictMode>
    )
  }

  return <NoInternetConnection />
}

export default App
