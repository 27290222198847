import React, { useState } from 'react'
import * as styled from './TiktokCard.styled'
import { IoIosHeart, IoIosShareAlt } from 'react-icons/io'
import { TiPlus } from 'react-icons/ti'
import { BsCheckCircleFill } from 'react-icons/bs'
import { IoMusicalNotesSharp } from 'react-icons/io5'

export type TiktokCardProps = {
  title: string
  description: string
  videoUrl: string
  likes: number
  img: string
  comments: number
  shares: number
}

const TiktokCard: React.FC<TiktokCardProps> = ({
  title,
  description,
  videoUrl,
  likes,
  img,
  comments,
  shares,
}) => {
  const [showMore, setShowMore] = useState(false)
  const formatLikes = (likes: number): string => {
    if (likes >= 1_000_000_000) {
      return `${(likes / 1_000_000_000).toFixed(1)}B` // Milyar
    } else if (likes >= 1_000_000) {
      return `${(likes / 1_000_000).toFixed(1)}M` // Milyon
    } else if (likes >= 1_000) {
      return `${(likes / 1_000).toFixed(1)}K` // Bin
    } else {
      return likes.toString() // Bin altı direkt sayı
    }
  }

  return (
    <a
      href={videoUrl}
      target="_blank"
      rel="noreferrer"
      style={{ color: 'inherit', textDecoration: 'none' }}
    >
      <styled.TiktokCardContainer>
        <img src="/cover.jpg" alt="image" />
        <div className="stats-column">
          <div className="avatar-icon">
            <img src="/avatar.jpg" alt="" />
            <div className="follow-button">
              <TiPlus className="plus" />
            </div>
          </div>
          <IoIosHeart className="stat-icon" />
          <span>{formatLikes(likes)}</span>
          <img className="stat-icon" src="/comment.png" alt="comment" />
          <span>{formatLikes(comments)}</span>
          <IoIosShareAlt className="stat-icon" />
          <span>{formatLikes(shares)}</span>
          <img
            className="music-plak"
            src="/music.png"
            alt="avatar"
            style={{
              width: '2.5rem',
              height: '2.5rem',
              marginTop: '1.5rem',
              borderRadius: '50%',
              overflow: 'hidden',
              margin: '0.5rem',
            }}
          />
          <div className="bottom">
            <div className="username">
              <h1 className="title">{title}</h1>
              <div className="verified">
                <BsCheckCircleFill />
              </div>
            </div>
            <p className="description">
              {showMore
                ? description
                : `${description.slice(0, 70)}${
                    description.length > 70 ? '...' : ''
                  }`}
              {description.length > 100 && (
                <span
                  style={{
                    color: 'white ',
                    cursor: 'pointer',
                    marginLeft: '5px',
                  }}
                >
                  {showMore ? 'Daha az' : 'Daha fazlası'}
                </span>
              )}
            </p>
            <div className="music">
              <IoMusicalNotesSharp />
              <span>Go Türkiye orijinal ses</span>
            </div>
          </div>
        </div>
      </styled.TiktokCardContainer>
    </a>
  )
}

export default TiktokCard
