import * as styled from "./TwitterCard.styled";
import { IoIosHeart, IoIosRepeat } from "react-icons/io";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { BsThreeDots } from "react-icons/bs";
import React from "react";

export type TwitterCardProps = {
  title: string;
  description: string;
  avatar: string;
  username: string;
  images: string[];
};

const highlightHashtags = (text: string) => {
  return text.split(" ").map((word, index) =>
    word.startsWith("#") ? (
      <span key={index} style={{ color: "#00ACEE", fontWeight: "bold" }}>
        {word}{" "}
      </span>
    ) : (
      `${word} `
    )
  );
};

const TwitterCard: React.FC<TwitterCardProps> = ({
  title,
  description,
  avatar,
  username,
  images,
}) => {
  return (
    <styled.TwitterCardContainer>
      <styled.TwitterCardHeader>
        <div className="left-top">
          <img src={avatar} alt="avatar" />
          <div className="user-info">
            <div className="title">
              <h1>{title}</h1>
              <RiVerifiedBadgeFill className="verified" />
            </div>
            <span className="username">@{username}</span>
          </div>
        </div>
        <div className="right-top">
          <div className="follow">
            <span className="follow-button">Takip et</span>
          </div>
          <BsThreeDots className="three-dots" />
        </div>
      </styled.TwitterCardHeader>

      <p className="description">{highlightHashtags(description)}</p>

      <styled.TwitterImagesGrid count={images.length}>
        {images.map((image, index) => (
          <div className="image" key={index}>
            <img src={image} alt="twitter" />
          </div>
        ))}
      </styled.TwitterImagesGrid>
    </styled.TwitterCardContainer>
  );
};

export default TwitterCard;
