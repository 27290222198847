import { request } from 'services/http/backoffice/instance'

import type {
  CreateDefinitionRequest,
  CheckYoutubeResponse,
  CheckEksiSozluk,
  Definition,
  DefinitionList,
  DefinitionResponse,
  CheckVkResponse,
  CreateYoutubeResponse,
  CreateEksiSozlukResponse,
  CreateTumblrResponse,
  CreateVkResponse,
  CreateInstagramResponse,
  CreateQuoraResponse,
  CreateTiktokResponse,
  CreateStarngageResponse,
  CreateMastodonResponse,
  CheckTumblr,
  CreateTwitterResponse,
  CreateRedditResponse,
  CreateNewsResponse,
  CreateInciSozlukResponse,
  CreateLinkedinResponse,
  CreateMemurlarResponse,
  CreateBookingResponse,
  CreateAjansPressResponse,
  CreateAirbnbResponse,
  UludagSozlukResponseValues,
  TripAdvisorResponseValues,
  WebsiteResponseValues,
  CreateGoogleTrendsResponse,
  TwitterQueryRequest,
  IDefinitionListRequest,
  CreateTwitterQueryRequest,
} from './types'

import {
  mapDefinitionForList,
  mapDefinitionForRequest,
  mapDefinitionForResponse,
  mapDefinitionVkForRequest,
  mapDefinitionCreateTwitterForRequest,
  mapDefinitionCreateRedditForRequest,
  mapDefinitionCreateEksiSozlukForRequest,
  mapDefinitionCreateVkForRequest,
  mapDefinitionEksiSozlukForResponse,
  mapDefinitionMastodonForRequest,
  mapDefinitionStarngageForRequest,
  mapDefinitionTumblrForRequest,
  mapDefinitionTiktokForRequest,
  mapDefinitionInstagramForRequest,
  mapDefinitionQuoraForRequest,
  mapDefinitionCreateNewsForRequest,
  mapDefinitionCreateYoutubeForRequest,
  mapDefinitionCreateInciSozlukForRequest,
  mapDefinitionLinkedinForRequest,
  mapDefinitionCreateBookingForRequest,
  mapDefinitionCreateAjansPressForRequest,
  mapDefinitionCreateAirbnbForRequest,
  mapDefinitionTripAdvisorForRequest,
  mapDefinitionUludagSozlukForRequest,
  mapDefinitionWebsiteForRequest,
  mapDefinitionCreateGoogleTrendsForRequest,
  definitionListFilterRequestParams,
  mapDefinitionQueryTwitterForRequest,
} from './mappers'

import { BaseContent } from '../types'
import moment from 'moment'
export const create = (data: CreateDefinitionRequest) =>
  request<Definition>({
    method: 'post',
    url: 'api/definition/create-definition',
    data,
    mappers: {
      req: mapDefinitionForRequest,
    },
  })

export const get = (
  value?: string,
  dataSourceValue?: string,
  pageIndex?: number,
  status?: string,
  environment?: string,
  pageSize?: number,
  countryPerception?: number
) =>
  request<DefinitionResponse>({
    method: 'get',
    url: 'api/definition/definition-list',
    params: {
      PageIndex: pageIndex,
      definitionValue: value,
      dataSource: dataSourceValue,
      // crawlingType: crawlingType,
      // definitionType: definitionType,
      countryPerception: countryPerception,
      status: status,
      environment: environment,
      PageSize: pageSize || 10,
    },
    mappers: {
      res: mapDefinitionForList,
    },
  })

export const getList = (params: IDefinitionListRequest) =>
  request<DefinitionResponse>({
    method: 'get',
    url: 'api/definition/definition-list',
    params: definitionListFilterRequestParams({
      ...params,
    }),
    mappers: {
      res: mapDefinitionForList,
    },
  })
export const checkDefinition = (user: string) => {
  return request<any>({
    method: 'post',
    url: 'api/definition/check/twitter/user',
    data: {
      userName: user,
    },
    // mappers: {
    //   req: mapDefinitionForRequest,
    // },
  })
}

export const checkYoutube = (url: string) =>
  request<BaseContent<CheckYoutubeResponse>>({
    method: 'post',
    url: 'api/definition/check-youtube',
    params: { url },
  })

export const checkVk = (data: any) =>
  request<BaseContent<CheckVkResponse>>({
    method: 'post',
    url: 'api/definition/check-vk-definition-value',
    data,
    mappers: {
      req: mapDefinitionVkForRequest,
    },
  })

export const checkTumblr = (data: any) =>
  request<CheckTumblr>({
    method: 'post',
    url: 'api/definition/check-tumblr-definition-value',
    data,
    mappers: {
      req: mapDefinitionTumblrForRequest,
    },
  })

export const checkEksiSozluk = (data: any) =>
  request<BaseContent<CheckEksiSozluk>>({
    method: 'post',
    url: 'api/definition/check-eksisozluk-definition-value',
    data,
    mappers: {
      req: mapDefinitionEksiSozlukForResponse,
    },
  })

export const getById = (id: string) =>
  request<Definition>({
    method: 'get',
    url: `api/definition/${id}`,
    mappers: {
      res: mapDefinitionForResponse,
    },
  })

export const getDefinition = (value?: string) =>
  request<DefinitionList[]>({
    method: 'get',
    url: 'definition-list',
    params: { value: value },
  })

export const getAllDefinitions = (definitionValue?: string) =>
  request<Definition[]>({
    method: 'get',
    url: 'api/definition/definition-list',
    params: { definitionValue, pageSize: 50, pageIndex: 0 },
    mappers: {
      res: (response) => response.data,
    },
  })
export const createTwitter = (data: CreateTwitterResponse) => {
  const isValidFetchSince = moment(
    data?.fetchSince,
    'YYYY-MM-DD',
    true
  ).isValid()
  const isValidFetchUntil = moment(
    data?.fetchUntil,
    'YYYY-MM-DD',
    true
  ).isValid()

  const fetchSince = isValidFetchSince ? data.fetchSince : new Date()
  const fetchUntil = isValidFetchUntil ? data.fetchUntil : new Date()
  return request<CreateTwitterResponse>({
    method: 'post',
    url: 'api/definition/create/twitter/multiple',
    data: {
      ...data,
      definitionValue: data.definitionValues,
      fetchSince,
      fetchUntil,
    },
    mappers: {
      req: mapDefinitionCreateTwitterForRequest,
    },
  })
}
export const createNews = (data: CreateNewsResponse) =>
  request<CreateNewsResponse>({
    method: 'post',
    url: 'api/definition/create/news',
    data,
    mappers: {
      req: mapDefinitionCreateNewsForRequest,
    },
  })

export const createYoutube = (data: CreateYoutubeResponse) =>
  request<CreateYoutubeResponse>({
    method: 'post',
    url: 'api/definition/create/youtube',
    data,
    mappers: {
      req: mapDefinitionCreateYoutubeForRequest,
    },
  })

export const createMemurlar = (data: CreateMemurlarResponse) =>
  request<CreateMemurlarResponse>({
    method: 'post',
    url: 'api/definition/create/memurlar',
    data,
    mappers: {
      req: mapDefinitionCreateYoutubeForRequest,
    },
  })

// /api/definition/count/twitter-fetch

export const twitterFetch = (data: CreateTwitterQueryRequest) => {
  const fetchKeywordSince = data.fetchKeywordSince
    ? `?fetchKeywordSince=${new Date(data.fetchKeywordSince).toDateString()}`
    : ''
  const fetchKeywordUntil = data.fetchKeywordUntil
    ? `&fetchKeywordUntil=${new Date(data.fetchKeywordUntil).toDateString()}`
    : ''
  return request<CreateTwitterQueryRequest>({
    method: 'get',
    url: `api/definition/count/twitter-fetch${fetchKeywordSince}${fetchKeywordUntil}`,
    params: {
      ...data,
    },
    mappers: {
      req: mapDefinitionQueryTwitterForRequest,
    },
  })
}

export const createReddit = (data: CreateRedditResponse) =>
  request<CreateRedditResponse>({
    method: 'post',
    url: 'api/definition/create/reddit',
    data,
    mappers: {
      req: mapDefinitionCreateRedditForRequest,
    },
  })

export const createEksiSozluk = (data: CreateEksiSozlukResponse) =>
  request<CreateEksiSozlukResponse>({
    method: 'post',
    url: 'api/definition/create/eksi-sozluk',
    data,
    mappers: {
      req: mapDefinitionCreateEksiSozlukForRequest,
    },
  })

export const createInciSozluk = (data: CreateInciSozlukResponse) =>
  request<CreateInciSozlukResponse>({
    method: 'post',
    url: 'api/definition/create/incisozluk',
    data,
    mappers: {
      req: mapDefinitionCreateInciSozlukForRequest,
    },
  })

export const createBooking = (data: CreateBookingResponse) =>
  request<CreateBookingResponse>({
    method: 'post',
    url: 'api/definition/create/booking',
    data,
    mappers: {
      req: mapDefinitionCreateBookingForRequest,
    },
  })
export const createGoogleTrends = (data: CreateGoogleTrendsResponse) =>
  request<CreateGoogleTrendsResponse>({
    method: 'post',
    url: 'api/definition/create/google-trends',
    data,
    mappers: {
      req: mapDefinitionCreateGoogleTrendsForRequest,
    },
  })
export const createAirbnb = (data: CreateAirbnbResponse) =>
  request<CreateAirbnbResponse>({
    method: 'post',
    url: 'api/definition/create/airbnb',
    data,
    mappers: {
      req: mapDefinitionCreateAirbnbForRequest,
    },
  })

export const createAjansPress = (data: CreateAjansPressResponse) =>
  request<CreateAjansPressResponse>({
    method: 'post',
    url: 'api/definition/create/ajans-press',
    data,
    mappers: {
      req: mapDefinitionCreateAjansPressForRequest,
    },
  })

export const createTumblr = (data: CreateTumblrResponse) =>
  request<CreateTumblrResponse>({
    method: 'post',
    url: 'api/definition/create/tumblr',
    data,
    mappers: {
      req: mapDefinitionTumblrForRequest,
    },
  })
export const createVk = (data: CreateVkResponse) =>
  request<CreateVkResponse>({
    method: 'post',
    url: 'api/definition/create/vk',
    data,
    mappers: {
      req: mapDefinitionCreateVkForRequest,
    },
  })
export const createInstagram = (data: CreateInstagramResponse) =>
  request<CreateInstagramResponse>({
    method: 'post',
    url: 'api/definition/create/instagram',
    data,
    mappers: {
      req: mapDefinitionInstagramForRequest,
    },
  })
export const createQuora = (data: CreateQuoraResponse) =>
  request<CreateQuoraResponse>({
    method: 'post',
    url: 'api/definition/create/quora',
    data,
    mappers: {
      req: mapDefinitionQuoraForRequest,
    },
  })
export const createTiktok = (data: CreateTiktokResponse) =>
  request<CreateTiktokResponse>({
    method: 'post',
    url: 'api/definition/create/tiktok',
    data,
    mappers: {
      req: mapDefinitionTiktokForRequest,
    },
  })
export const createStarngage = (data: CreateStarngageResponse) =>
  request<CreateStarngageResponse>({
    method: 'post',
    url: 'api/definition/create/starngage',
    data,
    mappers: {
      req: mapDefinitionStarngageForRequest,
    },
  })
export const createWebsite = (data: WebsiteResponseValues) =>
  request<WebsiteResponseValues>({
    method: 'post',
    url: 'api/definition/create/website',
    data,
    mappers: {
      req: mapDefinitionWebsiteForRequest,
    },
  })
export const createUludag = (data: UludagSozlukResponseValues) =>
  request<UludagSozlukResponseValues>({
    method: 'post',
    url: 'api/definition/create/uludagsozluk',
    data,
    mappers: {
      req: mapDefinitionUludagSozlukForRequest,
    },
  })
export const createTripAdvisor = (data: TripAdvisorResponseValues) =>
  request<TripAdvisorResponseValues>({
    method: 'post',
    url: 'api/definition/create/tripadvisor',
    data,
    mappers: {
      req: mapDefinitionTripAdvisorForRequest,
    },
  })
export const createMastodon = (data: CreateMastodonResponse) =>
  request<CreateMastodonResponse>({
    method: 'post',
    url: 'api/definition/create/mastodon',
    data,
    mappers: {
      req: mapDefinitionMastodonForRequest,
    },
  })
export const createLinkedin = (data: CreateLinkedinResponse) =>
  request<CreateLinkedinResponse>({
    method: 'post',
    url: 'api/definition/create/linkedin',
    data,
    mappers: {
      req: mapDefinitionLinkedinForRequest,
    },
  })

export const putById = (id: string) =>
  request<any>({
    method: 'patch',
    // url: `api/definition/pause-definition-task/${id}`,
    url: `api/definition/toggle-definition-pause/${id}`,
  })
