import styled from 'styled-components'

export const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 40px;
  background-color: #fff;

  min-width: 1123px;
  min-height: 794px;
  margin-bottom: 40px;
  border: 5px solid #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  page-break-after: always;

  .report-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    .report-header-title {
      h1 {
        font-size: 1.5rem;
        font-weight: 700;
      }
      width: 100%;
      border-bottom: 1px solid #000;
      padding-bottom: 1.4rem;
    }

    .report-header-logos {
      display: flex;
      gap: 1rem;

      .bukafa {
        img {
          height: 5rem;
        }
      }

      .ministry-tga {
        display: flex;
        gap: 1rem;
        img:nth-child(1) {
          border-right: 1px solid red;
          padding-right: 1rem;
        }
        img {
          height: 5rem;
        }
      }
    }
  }

  .report-aside {
    width: 1rem;
    height: 100%;
    background-color: red;
  }

  .report-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-left: 5px solid #d62727;
    padding-left: 1rem;
  }
`
