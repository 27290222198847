import styled from 'styled-components'

export const AdminContentStatsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  width: 1000px;
  position: relative; /* Çizgilerin konumlandırması için gerekli */
`

export const AdminStatsItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
  color: black;
  flex: 1; /* Eşit genişlik için */
  position: relative; /* Çizgiyi yerleştirmek için gerekli */
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  &:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 100%;
    transform: translateX(50%);
    background-color: #db0000;
    opacity: 0.5;
  }
`

export const AdminStatsData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: fit-content;
  font-size: 20px;
  font-weight: 400;
  div {
    display: flex;
    justify-content: left;
    text-align: left;
    align-items: end;
    .key {
      color: black;
    }
    .value {
      color: #db0000;
      font-weight: bold;
      text-align: left;
    }
  }
`
