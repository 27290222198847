import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import MainLayout from 'layouts/main'
import Page from '@zera-admin/page'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import { Column, PageHeader, Row } from '@zera-admin/page'
import { RadioGroup } from '@zera-admin/radio'
import Alert from '@zera-admin/alert'
import Button, { LinkButton } from '@zera-admin/button'
import Checkbox from '@zera-admin/checkbox'
import Input from '@zera-admin/input'
import Spinner from '@zera-admin/spinner'
import Select, { SelectOptionProps } from '@zera-admin/select'
import { useFlagcardContext } from 'app/contexts/flagcard'

import countryService, {
  CountryPerceptionsResponse,
} from 'services/http/backoffice/country-perception'
import fieldServices from 'services/http/backoffice/field'
import definitionService from 'services/http/backoffice/definition'
import { FieldLanguagesResponse } from 'services/http/backoffice/field/types'
import TwitterProfile from 'components/twitter-profile'
import { crawlingType, definitionType } from './constant'
import { DefinitionFormProps, SelectType, SocialMediaEnum } from '../types'
import Form, {
  FormFooter,
  FormHookSubmitProps,
  FormSection,
  useForm,
} from '@zera-admin/form'
import backofficeDefinitionService from 'services/http/backoffice/definition'
import { CreateTwitterResponse } from 'services/http/backoffice/definition/types'
import DateTimePicker from '@zera-admin/datetime-picker'
import { DateTimePickerHours } from 'app/shared/enums'
import { current30DaysBeforeString, currentDateString } from 'app/functions'
import { databaseOptions, streamLanguageOptions } from './constant'
import { set } from 'app/contexts/dashboard/actions'

const Twitter: React.FunctionComponent<DefinitionFormProps> = ({
  actions = [],
  onError,
  values,
}) => {
  const [newPerceptionChecked, setNewPerceptionChecked] = useState<boolean>()
  const [newPerception, setNewPerception] = useState<string>()
  const [isLoading, setIsLoading] = useState<boolean>()
  const [isLoadingTwitterProfile, setIsLoadingTwitterProfile] =
    useState<boolean>(false)
  const [languages, setLanguages] = useState<FieldLanguagesResponse>()
  const [account, setAccount] = useState<any>()
  const [isVerified, setIsVerified] = useState<boolean>(false)
  const [countryPerceptions, setCountryPerceptions] =
    useState<CountryPerceptionsResponse>()
  const [languageValue, setLanguageValue] = useState<[]>()
  const [keywordValue, setKeywordValue] = useState<string>('')
  const [countData, setCountData] = useState<any>()
  const [flagcard] = useFlagcardContext()
  const hoursArray: string[] = []
  for (const key in DateTimePickerHours) {
    if (typeof DateTimePickerHours[key] === 'string') {
      hoursArray.push(DateTimePickerHours[key])
    }
  }

  const profile = account?.accountInfo

  const checkDefinition = () => {
    setIsLoadingTwitterProfile(true)
    definitionService
      .checkDefinition(form.values.definitionValue || '')
      .then((res) => {
        if (res?.code === 200) {
          console.log(res, 'res checkDefinition')
          setAccount(res.data)
        }
        setIsLoadingTwitterProfile(false)
        onKeywordToDefinitionValue()
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setIsLoadingTwitterProfile(false)
      })
  }

  const checkUser = () => {
    if (keywordValue) {
      definitionService
        .checkDefinition(keywordValue || '')
        .then((res) => {
          if (res?.code === 200 && !res?.data?.accountInfo?.protected) {
            onKeywordToDefinitionValue()
          } else {
            flagcard.add({
              appearance: 'error',
              title: 'Bir hata oluştu',
              children:
                'Gizli hesaplardan veri çekilemez. Herkese açık bir hesap eklemeniz gerekmekte.',
            })
          }
        })
        .catch(() => {
          flagcard.add({
            appearance: 'error',
            title: 'Bir hata oluştu',
            children: 'Kullanıcı sorgulanırken hata oluştu',
          })
        })
    } else {
      flagcard.add({
        appearance: 'error',
        title: 'Bir hata oluştu',
        children: 'Kullanıcı adı giriniz',
      })
    }
  }

  const changeCrawlingSource = (source: SocialMediaEnum) => {
    form.handleFieldChange('crawlingSource', source)
  }

  const handleTwitter = () => {
    changeCrawlingSource(SocialMediaEnum.Twitter)
    form.handleBatchUpdate({
      crawlingType: form?.values.crawlingType,
      definitionValues: undefined,
      definitionType: undefined,
      historicalFetch: undefined,
      fetchComment: undefined,
      streamAllowReplies: undefined,
      period: undefined,
      periodicFetch: false,
      countryPerception: 1,
      allowedDbs: undefined,
      allowedLanguages: undefined,
      environment: 'Alice',
      definitionBlackLists: undefined,
      useIncomingDefinition: false,
      fetchSince: undefined,
      fetchUntil: undefined,
      changeTimeIntervalByRefreshInterval: undefined,
      conversationId: undefined,
      streamLanguage: undefined,
      useGnipForFetchByKeyword: false,
    })
    // resetform()
  }

  console.log(values, 'initial values')

  const form = useForm<CreateTwitterResponse>({
    initialValues: values,
    onSubmit: (props) => handleSubmit(props),
  })

  const handleSubmit = (props: FormHookSubmitProps<CreateTwitterResponse>) => {
    if (!props.errors) {
      if (form.values) {
        if (
          form?.values?.crawlingType === 'stream' &&
          !form?.values?.streamLanguage
        ) {
          flagcard.add({
            appearance: 'error',
            title: 'Bir hata oluştu',
            children: 'Akış dili seçiniz',
          })

          return
        }
        if (form?.values?.definitionValues?.length > 0) {
          backofficeDefinitionService
            .createTwitter(form.values)
            .then(() => {
              setCountData({})
              setLanguageValue([])

              flagcard.add({
                appearance: 'success',
                title: 'Kayıt işlemi başarılı',
                children:
                  'Girmiş olduğunuz bilgiler ile birlikte veri sisteme başarıyla kayıt edilmiştir. Dilerseniz yeni veri ekleyebilirsiniz.',
              })
              form.handleReset()
              setTimeout(() => {
                window.location.reload()
              }, 400)
            })
            .catch((error) => {
              console.log(error)
              flagcard.add({
                appearance: 'error',
                title: 'Bir hata oluştu',
                children:
                  'Veri eklenirken bir hata oluştu. Lütfen tekrar deneyin. Sorununuz düzelmediyse servis sağlayıcısı ile iletişime geçiniz.',
              })
            })
        } else {
          flagcard.add({
            appearance: 'error',
            title: 'Bir hata oluştu',
            children: 'En az bir tanımlama değeri giriniz',
          })
        }
      }
    } else {
      if (onError) {
        onError(props.errors)
      }
    }
  }

  const renderPageHeader = () => {
    const breadcrumbs = (
      <Breadcrumb>
        <BreadcrumbItem text="Anasayfa" href="/" />
        <BreadcrumbItem text="Tanımlama" href="/definition" />
        <BreadcrumbItem text="Tanımlama oluştur" href="/definition/create" />
        <BreadcrumbItem text="X" />
      </Breadcrumb>
    )

    const bottomBar = (
      <p>
        X ile alakali tanımlama oluşturabilir, varolanları düzenleyebilirsiniz.
      </p>
    )

    return (
      <PageHeader
        // actions={actions}
        bottomBar={bottomBar}
        breadcrumbs={breadcrumbs}
      >
        X
      </PageHeader>
    )
  }

  const countryPerceptionData = {
    customValue: '',
    fromCountry: '',
    toCountry: '',
    description: '',
    sendAsAlarm: true,
  }

  const handleCreateCountryPerception = (data: string) => {
    countryPerceptionData.customValue = data

    countryService
      .createCountryPerception(countryPerceptionData)
      .then((res) => {
        setIsLoading(false)
        handleGetCountryPerceptions(0, 0, res.data.data)
        setNewPerceptionChecked(false)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleCheckDefinition = () => {
    setIsVerified(true)
    checkDefinition()
  }

  const onKeywordToDefinitionValue = () => {
    const currentValue: string[] = []
    const currentDefinitionValue = form.values.definitionValues
    if (currentDefinitionValue && currentDefinitionValue?.length > 0) {
      currentDefinitionValue.forEach((currentData: string) => {
        currentValue.push(currentData)
      })
    }
    if (keywordValue) {
      currentValue.push(keywordValue)
      setKeywordValue('')
    }
    form.handleFieldChange('definitionValues', currentValue)
  }
  const onKeywordToDefinitionValueRemove = (index: number) => {
    const currentDefinitionValue = [...form.values.definitionValues]

    if (currentDefinitionValue && currentDefinitionValue?.[index]) {
      currentDefinitionValue.splice(index, 1)
    }
    form.handleFieldChange('definitionValues', currentDefinitionValue)
  }

  const handleGetCountryPerceptions = (
    pageSize: number = 0,
    pageIndex: number = 0,
    selectedItem?: any
  ) => {
    setIsLoading(true)
    countryService
      .getCountryPerceptions(1000, pageIndex)
      .then((res) => {
        setCountryPerceptions(res.data)
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  useEffect(() => {
    handleGetCountryPerceptions()
    handleTwitter()
    form.handleFieldChange('periodicFetch', false)
  }, [])

  const getFieldLanguages = () => {
    fieldServices
      .getFieldLanguages()
      .then((res) => {
        setLanguages(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {})
  }

  useEffect(() => {
    getFieldLanguages()
  }, [])

  const renderContent = () => {
    const getCountryPerceptionList = (data: any): SelectType[] => {
      const countryPerceptionList: SelectType[] = []
      data?.data.forEach((per: any) =>
        countryPerceptionList.push({ label: per.description, value: per.key })
      )
      return countryPerceptionList
    }

    const definitionValues = form?.values?.definitionValues as string[]

    return (
      <React.Fragment>
        <Form onSubmit={form.handleSubmit}>
          <FormSection
            title="Genel bilgiler"
            description="Form üzerinden aşağıdaki genel bilgileri doldurunuz"
          >
            <Row>
              <Column>
                <RadioGroup
                  label="Crawling type"
                  isRequired={true}
                  name="crawlingType"
                  onChange={(props) => {
                    setIsVerified(false)
                    setAccount({})
                    setCountData({})
                    form.handleFieldChange(
                      'crawlingType',
                      props.currentTarget.value
                    )
                  }}
                  options={crawlingType}
                />
              </Column>
            </Row>

            {form?.values?.crawlingType && (
              <Row>
                <Column xs={{ size: 12 }}>
                  <RadioGroup
                    label="Definition type"
                    name="definitionType"
                    isRequired={true}
                    onChange={(props) => {
                      setIsVerified(false)
                      setAccount({})
                      setCountData({})
                      form.handleFieldChange(
                        'definitionType',
                        props.currentTarget.value
                      )
                    }}
                    options={
                      form?.values?.crawlingType === 'fetch'
                        ? definitionType
                        : definitionType
                            .filter((item) => item.value !== 'reply')
                            .filter((item) => item.value !== 'favorite')
                            .filter((item) => item.value !== 'user-favorite')
                            .filter((item) => item.value !== 'follower')
                            .filter((item) => item.value !== 'friend')
                    }
                  />
                </Column>
              </Row>
            )}

            {(form?.values?.definitionType === 'account' ||
              form?.values?.definitionType === 'keyword' ||
              form?.values?.definitionType === 'reply') &&
              form?.values?.crawlingType && (
                <Row>
                  <Column xs={{ size: 4 }}>
                    <Select
                      label="Algı Seçiniz"
                      name="countryPerception"
                      options={getCountryPerceptionList(countryPerceptions)}
                      value={getCountryPerceptionList(
                        countryPerceptions
                      ).filter(
                        (dataSetType: any) =>
                          dataSetType.value === form.values.countryPerception
                      )}
                      onChange={(props) => {
                        const selectedValue =
                          (props as SelectOptionProps).value || 1
                        form.handleFieldChange(
                          'countryPerception',
                          selectedValue
                        )
                      }}
                      placeholder="Seçim yapın"
                    />
                  </Column>
                </Row>
              )}

            {(form?.values?.definitionType === 'account' ||
              form?.values?.definitionType === 'keyword' ||
              form?.values?.definitionType === 'reply') && (
              <Row>
                <Column xs={{ size: 4 }}>
                  <Select
                    label="Akış dili"
                    name="streamLanguage"
                    options={streamLanguageOptions}
                    value={streamLanguageOptions?.filter(
                      (item: any) => item.value === form.values.streamLanguage
                    )}
                    onChange={(props) => {
                      const selectedValue =
                        (props as SelectOptionProps).value || 1
                      form.handleFieldChange('streamLanguage', selectedValue)
                    }}
                    placeholder="Seçim yapın"
                  />
                </Column>
              </Row>
            )}

            {(form?.values?.definitionType === 'account' ||
              form?.values?.definitionType === 'keyword' ||
              form?.values?.definitionType === 'reply') &&
              form?.values?.crawlingType && (
                <Row>
                  <Column xs={{ size: 12 }}>
                    <Checkbox
                      name="perceptionFetch"
                      label="Yeni Algı eklemek ister misiniz?"
                      onChange={(props) => {
                        setNewPerceptionChecked(props.currentTarget.checked)
                      }}
                    />
                  </Column>
                </Row>
              )}
            {newPerceptionChecked && (
              <Row xs={{}}>
                <Column xs={{ size: 4 }}>
                  <Input
                    name="period"
                    placeholder="Bir algı giriniz"
                    description="Bir algı giriniz"
                    onChange={(props) => {
                      setNewPerception(props.currentTarget.value)
                    }}
                  />
                </Column>
                <Column xs={{ size: 4 }}>
                  <Button
                    onClick={() =>
                      handleCreateCountryPerception(newPerception!)
                    }
                    appearance="primary"
                    children="Ekle"
                    isLoading={isLoading}
                  />
                </Column>
              </Row>
            )}
            {(form?.values?.definitionType === 'account' ||
              form?.values?.definitionType === 'keyword' ||
              form?.values?.definitionType === 'reply') &&
              form?.values?.crawlingType && (
                <Row>
                  <Column xs={{ size: 4 }}>
                    <Select
                      label="Dil Seçiniz"
                      name="dataset"
                      value={languageValue}
                      options={
                        languages?.data
                          ? languages.data.map((lang: string) => {
                              return { label: lang, value: lang }
                            })
                          : []
                      }
                      onChange={(props: any) => {
                        form.handleFieldChange(
                          'allowedLanguages',
                          (props as SelectOptionProps[]).map(
                            (language) => language.value
                          )
                        )
                      }}
                      placeholder="Seçim yapın"
                      isMultiple={true}
                    />
                  </Column>
                </Row>
              )}
            {(form?.values?.definitionType === 'account' ||
              form?.values?.definitionType === 'keyword' ||
              form?.values?.definitionType === 'reply') &&
              form?.values?.crawlingType && (
                <Row>
                  <Column xs={{ size: 4 }}>
                    <Select
                      label="Veritabani seçiniz"
                      name="dataset"
                      options={[
                        { label: 'Neo4j', value: 'neo4j' },
                        { label: 'Oracle', value: 'oracle' },
                      ]}
                      onChange={(props: any) => {
                        form.handleFieldChange(
                          'allowedDbs',
                          (props as SelectOptionProps[]).map(
                            (database) => database.value
                          )
                        )
                      }}
                      placeholder="Seçim yapın"
                      isMultiple={true}
                      value={databaseOptions.filter((item) =>
                        form?.values?.allowedDbs?.includes(item.value)
                      )}
                    />
                  </Column>
                </Row>
              )}

            {(form?.values?.definitionType === 'account' ||
              form?.values?.definitionType === 'keyword' ||
              form?.values?.definitionType === 'reply') &&
              form?.values?.crawlingType && (
                <Row>
                  <Column xs={{ size: 4 }}>
                    <Select
                      label="Ortam seçiniz"
                      name="environment"
                      options={[{ label: 'Alice', value: 'Alice' }]}
                      onChange={(selectedOption) => {
                        const selectedValue = (
                          selectedOption as SelectOptionProps
                        ).value
                        form.handleFieldChange('environment', selectedValue)
                      }}
                      placeholder="Seçim yapın"
                      isMultiple={false}
                    />
                  </Column>
                </Row>
              )}

            {/* --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}

            {form.values.crawlingType === 'fetch' &&
              form.values.definitionType &&
              (form.values.definitionType === 'reply' ||
                form.values.definitionType === 'account') && (
                <Row
                  xs={{
                    align: 'center',
                  }}
                >
                  <React.Fragment>
                    <Column xs={{ size: 4 }}>
                      <Input
                        name="definitionValue"
                        label="Tanımlama Değeri"
                        placeholder="Kullanıcı adı giriniz"
                        description="Geçerli bir X kullanıcı adı giriniz"
                        onChange={(props) => {
                          form.handleFieldChange(
                            'definitionValue',
                            props.currentTarget.value
                          )
                        }}
                      />
                    </Column>
                    <Column>
                      <Button
                        onClick={() => handleCheckDefinition()}
                        appearance="primary"
                        children="Doğrula"
                      />
                    </Column>
                  </React.Fragment>
                </Row>
              )}

            {form.values.crawlingType === 'stream' &&
              form.values.definitionType === 'account' && (
                <>
                  <Row
                    xs={{
                      align: 'center',
                    }}
                  >
                    <React.Fragment>
                      <Column xs={{ size: 4 }}>
                        <Input
                          name="definitionValue"
                          placeholder="Kullanıcı adı giriniz"
                          label="Tanımlama Değeri"
                          description="Geçerli bir X kullanıcı adı giriniz"
                          onChange={(props) => {
                            form.handleFieldChange(
                              'definitionValue',
                              props.currentTarget.value
                            )
                            setKeywordValue(props.currentTarget.value)
                          }}
                          value={keywordValue}
                        />
                      </Column>
                      <Column>
                        <Button
                          onClick={() => handleCheckDefinition()}
                          appearance="primary"
                          children="Doğrula"
                        />
                      </Column>
                    </React.Fragment>
                  </Row>
                  <>
                    {definitionValues?.length > 0 && (
                      <Row>
                        <Column xs={{ size: 4 }}>
                          <strong>Hesaplar</strong>
                        </Column>
                      </Row>
                    )}
                    {definitionValues?.map((d, index) => (
                      <Row>
                        <Column xs={{ size: 4 }}>{d}</Column>
                        <Column>
                          <Button
                            onClick={() =>
                              onKeywordToDefinitionValueRemove(index)
                            }
                            appearance="error"
                            children="Kaldır"
                          />
                        </Column>
                      </Row>
                    ))}
                  </>
                </>
              )}
            {form.values.definitionType === 'keyword' && (
              <>
                <Row
                  xs={{
                    align: 'center',
                  }}
                >
                  <Column xs={{ size: 4 }}>
                    <Input
                      name="keyword"
                      placeholder="Keyword adı giriniz"
                      label="Tanımlama Değeri"
                      description="Veri toplamak için keyword giriniz (hashtag için # sembolü kullanınız)"
                      value={keywordValue}
                      onChange={(props) => {
                        setKeywordValue(props.currentTarget.value)
                      }}
                    />
                  </Column>
                  <Column>
                    <Button
                      onClick={() => onKeywordToDefinitionValue()}
                      appearance="primary"
                      children="Yeni Kelime"
                    />
                  </Column>
                </Row>
                <>
                  {definitionValues?.length > 0 && (
                    <Row>
                      <Column xs={{ size: 4 }}>
                        <strong>Kelimeler</strong>
                      </Column>
                    </Row>
                  )}
                  {definitionValues?.map((d, index) => (
                    <Row>
                      <Column xs={{ size: 4 }}>{d}</Column>
                      <Column>
                        <Button
                          onClick={() =>
                            onKeywordToDefinitionValueRemove(index)
                          }
                          appearance="error"
                          children="Kaldır"
                        />
                      </Column>
                    </Row>
                  ))}
                </>
              </>
            )}

            {form?.values?.definitionType === 'account' ||
              form?.values?.definitionType === 'keyword' ||
              (form?.values?.definitionType === 'reply' && (
                <Row>
                  <Column xs={{ size: 6 }}>
                    <Input
                      name="definitionBlackLists"
                      placeholder="Tanımlama bazlı blacklist için anahtar kelimeleri giriniz"
                      description="Blackliste eklemek istediginiz kelimeleri giriniz(Her kelimeden sonra , eklemeniz gerekmektedir)"
                      onChange={(props) => {
                        const inputString = props.currentTarget.value
                        const stringToArray = inputString.split(',')

                        form.handleFieldChange(
                          'definitionBlackLists',
                          stringToArray
                        )
                      }}
                    />
                  </Column>
                </Row>
              ))}

            {form.values.crawlingType === 'fetch' &&
              form.values.definitionType === 'reply' && (
                <Row>
                  <Column xs={{ size: 6 }}>
                    <Input
                      name="conversationId"
                      placeholder="conversationId giriniz."
                      description="Geçerli bir conversationId giriniz"
                      onChange={(props) => {
                        form.handleFieldChange(
                          'conversationId',
                          props.currentTarget.value
                        )
                      }}
                    />
                  </Column>
                </Row>
              )}

            {((form?.values?.definitionType === 'keyword' ||
              form?.values?.definitionType === 'account') &&
              form.values.crawlingType === 'fetch') ||
            (form?.values?.crawlingType === 'stream' &&
              form?.values?.historicalFetch) ? (
              <>
                <Row>
                  <Column xs={{ size: 4 }}>
                    <DateTimePicker
                      description="İşlenecek veri aralığının bitiş tarihini seçiniz"
                      label="Secili tarihten"
                      locale="tr"
                      name="fetchSince"
                      onChange={(props) => {
                        form.handleFieldChange('fetchSince', props)
                      }}
                      type="default"
                      datePickerProps={{
                        maxDate: form?.values?.fetchUntil
                          ? form?.values?.fetchUntil
                          : current30DaysBeforeString(),
                        minDate: '2006-03-21 03:00',
                      }}
                      value={
                        form?.values?.fetchSince
                          ? form?.values?.fetchSince
                          : current30DaysBeforeString()
                      }
                      times={hoursArray}
                    />
                  </Column>
                  <Column xs={{ size: 4 }}>
                    <DateTimePicker
                      description="İşlenecek veri aralığının bitiş tarihini seçiniz"
                      label="Secili tarihe"
                      locale="tr"
                      name="fetchUntil"
                      onChange={(props) => {
                        form.handleFieldChange('fetchUntil', props)
                      }}
                      type="default"
                      value={
                        form?.values?.fetchUntil
                          ? form?.values?.fetchUntil
                          : currentDateString()
                      }
                      datePickerProps={{
                        minDate: form?.values?.fetchSince
                          ? form?.values?.fetchSince
                          : '2006-03-21 03:00',
                        maxDate: currentDateString(),
                      }}
                      times={hoursArray}
                    />
                  </Column>
                </Row>
              </>
            ) : null}

            {form.values.crawlingType && isVerified && (
              <Column xs={{ size: 12 }}>
                {isLoadingTwitterProfile ? (
                  <Spinner />
                ) : (
                  <TwitterProfile
                    data={{
                      Name: profile?.name,
                      ScreenName: profile?.username,
                      Location: profile?.location,
                      Description: profile?.description,
                      Protected: profile?.protected,
                      Verified: profile?.verified,
                      FollowersCount: profile?.public_metrics?.followers_count,
                      FriendsCount: profile?.public_metrics?.following_count,
                      FavoritesCount: profile?.public_metrics?.listed_count,
                      StatusesCount: profile?.public_metrics?.tweet_count,
                      CreatedAt: profile?.created_at,
                      ProfileImageUrl: profile?.profile_image_url,
                      Gender: '',
                    }}
                  />
                )}
              </Column>
            )}

            {account?.data?.completedGroupIdList?.length > 0 ? (
              <Column xs={{ size: 12 }}>
                <h3>Geçmişe ait rapor kayıtları</h3>
                <br />
                <Row>
                  {account.data.completedGroupIdList.map(
                    (report: any, index: number) => (
                      <Column key={index} xs={{ size: 4 }}>
                        <Link to={`/definition/detail/${report.groupId}`}>
                          {new Date(report.createdAt).toLocaleString()}
                        </Link>
                      </Column>
                    )
                  )}
                </Row>
              </Column>
            ) : null}

            {account?.data?.processingGroupIdList?.length > 0 ? (
              <Column xs={{ size: 12 }}>
                <Alert
                  appearance="error"
                  title="Aktif rapor isteği bulunmaktadır."
                >
                  Devam eden süreci bulunan kullanıcılar için rapor isteği
                  oluşturamazsınız.
                  <br />
                  <br />
                  <LinkButton
                    appearance="primary"
                    href={`/definition/detail/${account.data.processingGroupIdList[0].groupId}`}
                  >
                    Raporu görmek için tıklayınız
                  </LinkButton>
                  <Button />
                </Alert>
              </Column>
            ) : null}

            {form.values.crawlingType === 'fetch' &&
              form.values.definitionType === 'keyword' && (
                <Column xs={{ size: 12 }}>
                  <Checkbox
                    name="useGnipForFetchByKeyword"
                    label="Enterprise kullanılsın mı?"
                    onChange={(props) => {
                      form.handleFieldChange(
                        'useGnipForFetchByKeyword',
                        props.currentTarget.checked
                      )
                    }}
                  />
                </Column>
              )}
            {(form?.values?.definitionType === 'account' ||
              form?.values?.definitionType === 'keyword' ||
              form?.values?.definitionType === 'reply') &&
            form.values.crawlingType === 'fetch' ? (
              <Column xs={{ size: 12 }}>
                <Checkbox
                  name="periodicFetch"
                  label="Periyodik olarak çekilsin mi ?"
                  onChange={(props) => {
                    form.handleFieldChange(
                      'periodicFetch',
                      props.currentTarget.checked
                    )
                  }}
                />
              </Column>
            ) : null}

            {form.values.definitionType &&
              form.values.crawlingType === 'fetch' &&
              form?.values?.periodicFetch && (
                <Row>
                  <Column xs={{ size: 6 }}>
                    <Input
                      name="period"
                      placeholder="Periyot giriniz"
                      description="Geçerli bir periyot giriniz"
                      onChange={(props) => {
                        form.handleFieldChange(
                          'period',
                          props.currentTarget.value
                        )
                      }}
                    />
                  </Column>
                </Row>
              )}

            {form.values.crawlingType === 'stream' &&
              form.values.definitionType && (
                <Column xs={{ size: 12 }}>
                  <Checkbox
                    name="historicalFetch"
                    label="Stream taskının bir de fetch taskı oluşsun mu?"
                    onChange={(props) => {
                      form.handleFieldChange(
                        'historicalFetch',
                        props.currentTarget.checked
                      )
                    }}
                  />
                </Column>
              )}

            {form.values.crawlingType === 'stream' &&
              form.values.historicalFetch && (
                <Column xs={{ size: 12 }}>
                  <Checkbox
                    name="useGnipForFetchByKeyword"
                    label="Enterprise kullanılsın mı?"
                    onChange={(props) => {
                      form.handleFieldChange(
                        'useGnipForFetchByKeyword',
                        props.currentTarget.checked
                      )
                    }}
                  />
                </Column>
              )}

            {form.values.definitionType === 'account' && (
              <Column xs={{ size: 12 }}>
                <Checkbox
                  name="fetchComment"
                  label="Tanimlamadan gelen postların bir de reply taskları oluşturulsun mu ?"
                  onChange={(props) => {
                    form.handleFieldChange(
                      'fetchComment',
                      props.currentTarget.checked
                    )
                  }}
                />
              </Column>
            )}

            {form.values.definitionType &&
              form.values.crawlingType === 'stream' && (
                <Column xs={{ size: 12 }}>
                  <Checkbox
                    name="useIncomingDefinition"
                    label="Tanımlamadan gelen postlar direkt olarak bu tanımlama ile işaretlensin mi ?"
                    onChange={(props) => {
                      form.handleFieldChange(
                        'useIncomingDefinition',
                        props.currentTarget.checked
                      )
                    }}
                  />
                </Column>
              )}

            {(form.values.crawlingType === 'fetch' &&
              form.values.definitionType === 'account') ||
            (form.values.crawlingType === 'fetch' &&
              form.values.definitionType === 'keyword') ? (
              <Column xs={{ size: 12 }}>
                <Checkbox
                  name="useIncomingDefinition"
                  label="Tanımlamadan gelen postlar direkt olarak bu tanımlama ile işaretlensin mi?"
                  onChange={(props) => {
                    form.handleFieldChange(
                      'useIncomingDefinition',
                      props.currentTarget.checked
                    )
                  }}
                />
              </Column>
            ) : null}

            {form.values.crawlingType === 'fetch' &&
              form.values.definitionType === 'keyword' && (
                <Column xs={{ size: 12 }}>
                  <Checkbox
                    name="changeTimeIntervalByRefreshInterval"
                    label="Yenileme aralığına göre zaman aralığını değiştirmek ister misiniz?"
                    onChange={(props) => {
                      form.handleFieldChange(
                        'changeTimeIntervalByRefreshInterval',
                        props.currentTarget.checked
                      )
                    }}
                  />
                </Column>
              )}

            {countData && countData?.resultByDates && (
              <div style={{ marginTop: '2vh' }}>
                <Row style={{ display: 'flex', flexDirection: 'column' }}>
                  <Column xs={{ size: 4 }}>
                    <span style={{ fontSize: '1rem', fontWeight: 600 }}>
                      Zaman Aralığı ve Değerleri:
                    </span>
                  </Column>
                  <Column xs={{ size: 12 }}>
                    <span style={{ fontSize: '0.9rem', fontWeight: 600 }}>
                      Toplam Sayı:
                    </span>
                    <span>&nbsp;{countData.totalCount}</span>
                  </Column>
                  {countData.resultByDates
                    ?.filter((item: any) => item.count > 0)
                    .map((item: any) => (
                      <div
                        style={{
                          borderBottom: '1px solid black',
                          width: '30%',
                          marginTop: '2vh',
                        }}
                      >
                        <Row>
                          <Column xs={{ size: 6 }}>
                            <span
                              style={{ fontSize: '0.85rem', fontWeight: 600 }}
                            >
                              Değer:
                            </span>
                            &nbsp;{item.count}
                          </Column>
                        </Row>
                        <Row>
                          <Column xs={{ size: 9 }}>
                            <span
                              style={{ fontSize: '0.85rem', fontWeight: 600 }}
                            >
                              Zaman Aralığı:
                            </span>
                            &nbsp;{item.timePeriod}
                          </Column>
                        </Row>
                      </div>
                    ))}
                </Row>
              </div>
            )}

            {form.values.definitionType === 'account' &&
              form.values.crawlingType === 'stream' && (
                <Column xs={{ size: 12 }}>
                  <Checkbox
                    name="streamAllowReplies"
                    label="Tanimlamadan gelen yanitlara izin verilsin mi?"
                    onChange={(props) => {
                      form.handleFieldChange(
                        'streamAllowReplies',
                        props.currentTarget.checked
                      )
                    }}
                  />
                </Column>
              )}
            {form.values.definitionType === 'favorite' ||
            form.values.definitionType === 'user-favorite' ||
            form.values.definitionType === 'follower' ||
            form.values.definitionType === 'friend' ? (
              <>
                <Row
                  xs={{
                    align: 'center',
                  }}
                >
                  <Column xs={{ size: 4 }}>
                    <Input
                      name="keyword"
                      label="Tanımlama Değeri"
                      placeholder={
                        form.values.definitionType === 'favorite'
                          ? 'Tweet entity key giriniz'
                          : 'X kullanıcı adı giriniz'
                      }
                      description={
                        form.values.definitionType === 'favorite'
                          ? 'Geçerli bi tweet entity key giriniz'
                          : 'Geçerli bir X kullanıcı adı giriniz'
                      }
                      value={keywordValue}
                      onChange={(props) => {
                        setKeywordValue(props.currentTarget.value)
                      }}
                    />
                  </Column>
                  <Column>
                    <Button
                      onClick={() =>
                        form.values.definitionType === 'favorite'
                          ? onKeywordToDefinitionValue()
                          : checkUser()
                      }
                      appearance="primary"
                      children="Yeni Ekle"
                    />
                  </Column>
                </Row>

                <>
                  {definitionValues?.length > 0 && (
                    <Row>
                      <Column xs={{ size: 4 }}>
                        <strong>Veriler</strong>
                      </Column>
                    </Row>
                  )}
                  {definitionValues?.map((d, index) => (
                    <Row>
                      <Column xs={{ size: 4 }}>{d}</Column>
                      <Column>
                        <Button
                          onClick={() =>
                            onKeywordToDefinitionValueRemove(index)
                          }
                          appearance="error"
                          children="Kaldır"
                        />
                      </Column>
                    </Row>
                  ))}
                </>

                <Row>
                  <Column xs={{ size: 4 }}>
                    <Input
                      name="limit"
                      type="number"
                      placeholder="Limit giriniz"
                      description="Limit giriniz"
                      value={form.values.limit}
                      onChange={(props) => {
                        form.handleFieldChange(
                          'limit',
                          props.currentTarget.value
                        )
                      }}
                    />
                  </Column>
                </Row>
              </>
            ) : null}
          </FormSection>
          <FormFooter>
            {form.values.crawlingType === 'stream' ||
            form.values.crawlingType === 'fetch' ? (
              <Button
                appearance="primary"
                children="Tanımlama oluştur"
                isDisabled={form?.values?.definitionType ? false : true}
                isLoading={isLoading}
                type="submit"
              />
            ) : null}
          </FormFooter>
        </Form>
      </React.Fragment>
    )
  }

  return (
    <MainLayout>
      <Page>
        {renderPageHeader()}
        {renderContent()}
      </Page>
    </MainLayout>
  )
}

export default Twitter
