import styled from "styled-components";

export const TwitterCardContainer = styled.div`
  width: 300px;
  height: fit-content;
  max-height: 500px;
  margin: 1rem;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  .description {
    margin-bottom: 20px;
    font-size: 12px;
  }
`;

export const TwitterCardHeader = styled.div`
  display: flex;
  align-items: start;
  margin-bottom: 20px;
  justify-content: space-between;
  .left-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-right: 10px;
    }
    .title {
      display: flex;
      align-items: center;
      font-size: 13px;
      h1 {
        margin-bottom: 0px;
      }

      .verified {
        color: #1da1f2;
        font-size: 12px;
      }
    }
    .user-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      h1 {
        font-size: 13px;
        margin-bottom: 0px;
      }

      .username {
        font-size: 12px;
        color: #657786;
      }
    }
  }
  .right-top {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    align-items: start;
    .follow {
      font-weight: bold;
      background-color: black;
      padding: 5px 10px;
      color: white;
      padding: 5px 10px;
      border-radius: 20px;
      font-size: 11px;
    }
    .three-dots {
      font-size: 20px;
      color: #657786;
    }
  }
`;

interface TwitterImagesGridProps {
  count: number;
}

export const TwitterImagesGrid = styled.div<TwitterImagesGridProps>`
  display: grid;
  gap: 2px;
  width: 100%;
  max-width: 300px;
  height: 200px;
  border-radius: 10px;
  overflow: hidden;

  ${({ count }) => {
    switch (count) {
      case 1:
        return "grid-template-columns: 1fr;";
      case 2:
        return `grid-template-columns: repeat(2, 1fr); grid-template-rows: 1fr;`;
      case 3:
        return `
          grid-template-columns: repeat(2, 1fr); 
          grid-template-rows: repeat(2, 1fr); 
          & > :nth-child(1) { grid-row: span 2; }`;
      case 4:
        return "grid-template-columns: 1fr 1fr; grid-template-rows: 1fr 1fr;";
      default:
        return "grid-template-columns: 1fr 1fr; grid-template-rows: 1fr 1fr;";
    }
  }}

  .image {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
