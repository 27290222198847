import React, { useEffect, useState } from 'react'
import MainLayout from 'layouts/main'
import { useFlagcardContext } from 'app/contexts/flagcard'
import { getCountryPerceptions } from '../../../services/http/backoffice/country-perception/endpoints'
import backofficeDefinitionService from 'services/http/backoffice/definition/'
import definitionService from 'services/http/backoffice/definition'

import { CreateEksiSozlukResponse } from './../../../services/http/backoffice/definition/types'
import { IFormValuesDefCheck } from './types'

import Page from '@zera-admin/page'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import { Column, Row, PageHeader } from '@zera-admin/page'
import { RadioGroup } from '@zera-admin/radio'
import Button from '@zera-admin/button'
import Input from '@zera-admin/input'
import Select, { SelectOptionProps } from '@zera-admin/select'
import Checkbox from '@zera-admin/checkbox'
import Icon from '@zera-admin/icon'
import Form, {
  FormSection,
  useForm,
  FormHookSubmitProps,
  FormFooter,
  FormHeader,
} from '@zera-admin/form'

const EksiSozluk = () => {
  const [formValues, setFormValues] = useState<IFormValuesDefCheck>({
    countryPerceptions: [],
    definitionValueCheck: { value: undefined, validity: false },
  })
  const [flagcard] = useFlagcardContext()

  useEffect(() => {
    getCountryPerceptions()
      .then((res) => res.data.data)
      .then((res) =>
        setFormValues((state) => ({
          ...state,
          countryPerceptions: res.map((country: any) => {
            return { label: country?.description, value: country?.key }
          }),
        }))
      )
  }, [])

  const initialValues: CreateEksiSozlukResponse = {
    crawlingType: 'fetch',
    definitionType: 'keyword',
    definitionValue: undefined,
    countryPerception: 1,
    allowedLanguages: undefined,
    allowedDbs: undefined,
    useIncomingDefinition: false,
    environment: undefined,
    definitionBlackLists: undefined,
    periodicFetch: false,
    period: undefined,
  }

  const form = useForm<CreateEksiSozlukResponse>({
    initialValues,
    onSubmit: (props) => handlerSubmit(props),
  })

  const handlerSubmit = (
    props: FormHookSubmitProps<CreateEksiSozlukResponse>
  ) => {
    console.log(form.values)
    if (!props.errors) {
      if (form.values) {
        backofficeDefinitionService
          .createEksiSozluk(form.values)
          .then(() => {
            flagcard.add({
              appearance: 'success',
              title: 'Kayıt işlemi başarılı',
              children:
                'Girmiş olduğunuz bilgiler ile birlikte veri sisteme başarıyla kayıt edilmiştir. Dilerseniz yeni veri ekleyebilirsiniz.',
            })
            form.handleBatchUpdate(initialValues)
          })
          .catch((error) => {
            console.log(error)
            flagcard.add({
              appearance: 'error',
              title: 'Bir hata oluştu',
              children:
                'Veri eklenirken bir hata oluştu. Lütfen tekrar deneyin. Sorununuz düzelmediyse servis sağlayıcısı ile iletişime geçiniz.',
            })
          })
      }
    }
  }

  const renderCheckDefinitionForm = () => {
    const handleDefinitionValueCheck = () => {
      definitionService
        .checkEksiSozluk({
          // crawlingSource: string,
          crawlingType: 'fetch',
          definitionType: 'keyword',
          definitionValue: formValues.definitionValueCheck.value,
        })
        .then((res) => {
          setFormValues((prevState) => ({
            ...prevState,
            definitionValueCheck: {
              ...prevState.definitionValueCheck,
              validity: res.code === 200,
            },
          }))
          res.code === 200
            ? flagcard.add({
                appearance: 'success',
                title: 'Tanımlama Kontrolü Başarılı',
                children: 'Tanımlama formu oluşturma ekranı yükleniyor...',
              })
            : flagcard.add({
                appearance: 'error',
                title: 'Tanımlama Kontrolü Başarısız',
                children:
                  'Belirtilen tanımlama değeri kullanılamıyor. Lütfen başka değer deneyin.',
              })
        })
    }

    return (
      <React.Fragment>
        <FormSection>
          <Row>
            <Column xs={{ size: 12 }} md={{ size: 6 }} xl={{ size: 4 }}>
              <Input
                label="*Definition Value"
                name="definitionValue"
                placeholder="Takip edilecek kelime giriniz."
                description="Tanımlamanın kullanılabilirliğini kontrol etmek için tanımlama değeri girin."
                isRequired
                value={form.values.definitionValue}
                onChange={(e) =>
                  setFormValues((prevState) => ({
                    ...prevState,
                    definitionValueCheck: {
                      ...prevState.definitionValueCheck,
                      value: e.currentTarget.value,
                    },
                  }))
                }
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <Button
                type="button"
                appearance="primary"
                children="Doğrula"
                iconBefore={<Icon name="check-circle-outline" />}
                onClick={(e) => {
                  handleDefinitionValueCheck()
                }}
              />
            </Column>
          </Row>
        </FormSection>
      </React.Fragment>
    )
  }

  const renderCreateDefinitionForm = () => {
    return (
      <React.Fragment>
        <FormSection>
          <Row>
            <Column md={{ size: 3 }}>
              <RadioGroup
                label="*Crawling Type"
                name="crawlingType"
                isRequired
                options={[{ label: 'Fetch', value: 'fetch' }]}
                value={form.values.crawlingType}
                onChange={(e) =>
                  form.handleFieldChange('crawlingType', e.currentTarget.value)
                }
              />
            </Column>
          </Row>
          <Row>
            <Column md={{ size: 3 }}>
              <RadioGroup
                label="*Definition Type"
                name="definitionType"
                isRequired
                options={[{ label: 'Keyword', value: 'keyword' }]}
                value={form.values.definitionType}
                onChange={(e) => {
                  form.handleFieldChange(
                    'definitionType',
                    e.currentTarget.value
                  )
                }}
              />
            </Column>
          </Row>
          <Row>
            <Column xs={{ size: 12 }} md={{ size: 6 }} xl={{ size: 4 }}>
              <Input
                label="*Definition Value"
                name="definitionValue"
                placeholder="Takip edilecek kelime giriniz."
                isRequired
                value={form.values.definitionValue}
                onChange={(e) => {
                  form.handleFieldChange(
                    'definitionValue',
                    e.currentTarget.value
                  )
                }}
              />
            </Column>
          </Row>
          <Row>
            <Column xs={{ size: 12 }} md={{ size: 3 }}>
              <Select
                label="Algı Seçiniz"
                name="countryPerception"
                placeholder="Seçim yapın"
                options={formValues.countryPerceptions}
                defaultValue={
                  formValues.countryPerceptions.find(
                    (option) => option.label === 'Türkiye'
                  ) || { label: 'Türkiye', value: 1 }
                }
                value={formValues.countryPerceptions.find(
                  (option) => option.value === form.values.countryPerception
                )}
                onChange={(e) => {
                  form.handleFieldChange(
                    'countryPerception',
                    (e as SelectOptionProps).value
                  )
                }}
              />
            </Column>
          </Row>
          <Row>
            <Column xs={{ size: 12 }} md={{ size: 3 }}>
              <Select
                label="Dil Seçiniz"
                name="allowedLanguages"
                description="*İzin verilen diller listesi"
                placeholder="Seçim yapın"
                isMultiple
                options={[
                  { label: 'Turkish', value: 'Turkish' },
                  { label: 'English', value: 'English' },
                ]}
                value={form.values.allowedLanguages?.map((lang) => {
                  return { label: lang, value: lang }
                })}
                onChange={(e) => {
                  const value = e as Array<SelectOptionProps>
                  form.handleFieldChange(
                    'allowedLanguages',
                    value.length > 0
                      ? value.map((lang) => lang.value)
                      : undefined
                  )
                }}
              />
            </Column>
          </Row>
          <Row>
            <Column xs={{ size: 12 }} md={{ size: 3 }}>
              <Select
                label="Database Seçimi"
                name="allowedDbs"
                description="*Çekilen verilerin işlendikten sonra hangi db 'lere kaydedileceğini belirtir."
                placeholder="Seçim yapın"
                isMultiple
                options={[
                  { label: 'Oracle', value: 'oracle' },
                  { label: 'Druid', value: 'druid' },
                  { label: 'Neo4j', value: 'neo4j' },
                ]}
                value={form.values.allowedDbs?.map((db) => ({
                  label: db,
                  value: db,
                }))}
                onChange={(e) => {
                  const value = e as Array<SelectOptionProps>
                  form.handleFieldChange(
                    'allowedDbs',
                    value.length > 0 ? value.map((db) => db.value) : undefined
                  )
                }}
              />
            </Column>
          </Row>
          <Row>
            <Column xs={{ size: 12 }} md={{ size: 3 }}>
              <Select
                label="Environment"
                name="environment"
                placeholder="Seçim yapın"
                options={[
                  { label: 'Alice', value: 'Alice' },
                  { label: 'Thoth', value: 'Thoth' },
                ]}
                value={
                  form.values.environment
                    ? [
                        {
                          label: form.values.environment,
                          value: form.values.environment,
                        },
                      ]
                    : null
                }
                onChange={(e) => {
                  form.handleFieldChange(
                    'environment',
                    (e as SelectOptionProps).value
                  )
                }}
              />
            </Column>
          </Row>
          <Row>
            <Column xs={{ size: 12 }} md={{ size: 3 }}>
              <Select
                label="Definition Black Lists"
                name="definitionBlackLists"
                placeholder="Seçim yapın"
                isMultiple
                options={[{ label: 'BlackListWord', value: 'BlackListWord' }]}
                value={form.values.definitionBlackLists?.map((blackList) => ({
                  label: blackList,
                  value: blackList,
                }))}
                onChange={(e) => {
                  const value = e as Array<SelectOptionProps>
                  form.handleFieldChange(
                    'definitionBlackLists',
                    value.length > 0
                      ? value.map((blackList) => blackList.value)
                      : undefined
                  )
                }}
              />
            </Column>
          </Row>
          <Row>
            <Column xs={{ size: 12 }} xl={{ size: 6 }}>
              <Checkbox
                label="Bu definitiondan gelen postlar direkt olarak bu definition ile işaretlensin mi işaretlenmesin mi?"
                name="useIncomingDefinition"
                isChecked={form.values.useIncomingDefinition}
                onChange={(e) => {
                  form.handleFieldChange(
                    'useIncomingDefinition',
                    e.currentTarget.checked
                  )
                }}
              />
            </Column>
          </Row>
          <Row>
            <Column xs={{ size: 12 }} xl={{ size: 6 }}>
              <Checkbox
                label="Periyodik olarak çekilsin mi ?"
                name="periodicFetch"
                isChecked={form.values.periodicFetch}
                onChange={(e) =>
                  form.handleFieldChange(
                    'periodicFetch',
                    e.currentTarget.checked
                  )
                }
              />
            </Column>
          </Row>
          {form.values.periodicFetch && (
            <Row>
              <Column xs={{ size: 12 }} md={{ size: 6 }} xl={{ size: 4 }}>
                <Input
                  label="*Period"
                  name="period"
                  placeholder="Periyot giriniz (0 * * * *)"
                  description="Geçerli bir periyot giriniz"
                  isRequired={form.values.periodicFetch}
                  value={form.values.period}
                  onChange={(e) => {
                    form.handleFieldChange('period', e.currentTarget.value)
                  }}
                />
              </Column>
            </Row>
          )}
        </FormSection>

        <FormFooter align="start">
          <Button
            type="submit"
            appearance="primary"
            children="Tanımlama Oluştur"
            iconBefore={<Icon name="add-item" />}
          />
        </FormFooter>
      </React.Fragment>
    )
  }

  return (
    <MainLayout>
      <Page>
        <PageHeader
          breadcrumbs={
            <Breadcrumb>
              <BreadcrumbItem text="Anasayfa" href="/" />
              <BreadcrumbItem text="Tanımlama" href="/definition" />
              <BreadcrumbItem
                text="Tanımlama oluştur"
                href="/definition/create"
              />
              <BreadcrumbItem text="Ekşi Sözlük" />
            </Breadcrumb>
          }
        />
        <Form onSubmit={form.handleSubmit}>
          <FormHeader
            title="Ekşi Sözlük Tanımlama Formu"
            description={
              <div style={{ marginBottom: '1.5rem' }}>
                <p>Form üzerinden aşağıdaki genel bilgileri doldurunuz.</p>
                {formValues.definitionValueCheck.validity && (
                  <p style={{ margin: '0', color: '#79AC78' }}>
                    Tanımlama kontrolü başarılı, artık tanımlama
                    oluşturabilirsiniz.
                  </p>
                )}
                <p style={{ margin: '0', color: '#A73121' }}>
                  Doldurulması zorunlu alanlar (*) işareti ile belirtilmiştir.
                </p>
              </div>
            }
          />
          {formValues.definitionValueCheck.validity
            ? renderCreateDefinitionForm()
            : renderCheckDefinitionForm()}
        </Form>
      </Page>
    </MainLayout>
  )
}

export default EksiSozluk
